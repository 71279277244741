import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios';

import { useUser } from '../../../store/userState';
import {Card, Avatar, Typography, List, ListItem, ListItemPrefix,} from "@material-tailwind/react";
import { PresentationChartBarIcon, UserCircleIcon, Cog6ToothIcon, PowerIcon, UserGroupIcon, RectangleGroupIcon } from "@heroicons/react/24/solid";

export default function AdminSidebar() {

    const [userImgUrl, setUserImgUrl] = useState()

    const navigate = useNavigate()
    const { user } = useUser();
    const role = user.role.charAt(0).toUpperCase() + user.role.slice(1);
    const profImageLink = `https://nexus-portal.s3.amazonaws.com/profileImages/${userImgUrl}`

    useEffect(() => {
        axios.get(`/api/users/get-user/${user._id}`)
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get users");
                } else {
                    setUserImgUrl(response.data.profileImage);
                    console.log(response.data.profileImage); 
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    });

    const handleLogout = () => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('id')
        navigate('/login')
    }

    return (
        <Card className="h-full w-full max-w-[20rem] p-4 shadow-xl  bg-[#F8F8FF] bg-opacity-0 text-white rounded-none  overflow-hidden ">
            <div className="flex items-center gap-4 mb-2 p-4">
            <Avatar src={ userImgUrl ? profImageLink : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" } alt="avatar" size="xxl" className="border-4 border-[#22D2EF]" />
            <div>
                <Typography variant="h6">{user.firstName} {user.lastName}</Typography>
                <Typography variant="small" color="gray" className="font-normal">
                {role}
                </Typography>
            </div>
            </div>
            <List>
            <NavLink to="/adminNXS0011/dashboard">
                <ListItem className="text-white" >
                    <ListItemPrefix>
                    <PresentationChartBarIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    Dashboard
                </ListItem>
            </NavLink>

            <NavLink to='/adminNXS0011/manage-users'>
                <ListItem className="text-white">
                    <ListItemPrefix>
                    <UserGroupIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    Manage Users
                </ListItem>
            </NavLink>

            <NavLink to='/adminNXS0011/manage-departments'>
            <ListItem className="text-white" >
                <ListItemPrefix>
                <RectangleGroupIcon className="h-5 w-5" />
                </ListItemPrefix>
                Manage Departments
            </ListItem>
            </NavLink>

            <hr className="my-2 border-blue-gray-50" />
            <Link to='/profile-settings'>
            <ListItem className="text-white">
                <ListItemPrefix>
                <UserCircleIcon className="h-5 w-5" />
                </ListItemPrefix>
                Profile
            </ListItem>
            </Link>
            <ListItem className="text-white">
                <ListItemPrefix>
                <Cog6ToothIcon className="h-5 w-5" />
                </ListItemPrefix>
                Settings
            </ListItem>
            <ListItem className="text-white" onClick={handleLogout}>
            <ListItemPrefix>
                <PowerIcon className="h-5 w-5" />
            </ListItemPrefix>
            Log Out
            </ListItem>
            </List>
        </Card>
    );
}
