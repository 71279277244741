import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input
} from "@material-tailwind/react";

export default function AddDepartments() {
  const [open, setOpen] = useState(false);
  const [addDepartment, setAddDepartment] = useState({department: ''})

  const handleOpen = () => setOpen(!open);

  const handleAddDepts = () => {

    axios.post('/api/departments/add', addDepartment)
    .then(response => {
        if (response.data.message) {
            console.log('Added: ', addDepartment.department);
            toast.success(`${response.data.message}`);
            setOpen(!open);
            window.location.reload()
        } else {
            console.log("New dept: ", response.data.result);
            toast.error(`${response.data.message}`);
        }
    })
    .catch(error => {
        console.error('Error:', error);
        const errorMessage = error.response?.data?.message || 'An error occurred during add.';
        toast.error(errorMessage);
    })
};

  return (
    <>
      <Button onClick={handleOpen} variant="gradient" color="blue">
        New Department
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogHeader>Add new department</DialogHeader>
        <DialogBody>
            <Input variant="standard" label="Department Name" placeholder="Name" onChange={e => setAddDepartment({addDepartment, department: e.target.value})}/>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleAddDepts}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}