import React from 'react'
import { useNavigate } from 'react-router-dom'
import FloatBar from '../../../Components/FloatBar'
import Sidebar from '../../../Components/Sidebar'
import networkbg from '../../../assets/networkbg.png'
import { ArrowUturnLeftIcon, CodeBracketSquareIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

const WarehouseToolsView = () => {
    const navigate = useNavigate()

    const handlePreviousPage = () => {
        navigate(-1)
    }

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1 relative overflow-hidden'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.9), rgba(17, 24, 38, 0.7)), url(${networkbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
            <FloatBar/>
            <div className='flex flex-row gap-2 w-[90%] h-full  '>
                <Sidebar/>

                <div className='flex flex-col'>
                    <div className='w-full h-fit flex justify-between p-4 text-white'>
                        <p className=' text-xl flex items-center'><ArrowUturnLeftIcon className=' mr-5 h-6 w-6 cursor-pointer hover:scale-125 transition' onClick={handlePreviousPage}/>Warehouse Tools</p>
                    </div>
                    <div className='w-fit h-fit gap-4 flex flex-wrap items-center justify-center bg-opacity-20 p-10'>
                    <Link to="/warehouse-tools/warehouse-refunds">   
                    <article className="hover:-translate-y-3 transition relative isolate flex flex-col justify-end overflow-hidden rounded-2xl px-8 pb-8 pt-40 w-[20rem] mx-auto">
                        <img src="https://venturebeat.com/wp-content/uploads/2021/10/shutterstock_1703415496-1.jpg?w=1200&strip=all" alt="University of Southern California" className="absolute inset-0 h-full w-full object-cover"/>
                        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
                        <h3 className="z-10 mt-3 text-3xl font-bold text-white">Warehouse Refunds</h3>
                        <div className="z-10 gap-y-1 overflow-hidden text-sm leading-6 text-gray-300 flex items-center">Active <div class="ml-2 w-3 h-3 rounded-full bg-green-500"></div></div>
                    </article>
                    </Link>
                    </div>
                </div>
            </div>
        </div>         
    )
}

export default WarehouseToolsView
