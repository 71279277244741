import { useState } from "react"
import { Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody, Typography, Spinner } from "@material-tailwind/react"
import { HomeIcon, BellIcon, CurrencyDollarIcon } from "@heroicons/react/24/solid"

export function RefundStepper() {
    const [isDone, setIsDone] = useState(false)

    return (
        <div className="w-[100%] h-[100%]">
        <Timeline>
            <TimelineItem>
                <TimelineConnector />
                <TimelineHeader>
                    <TimelineIcon className="p-2">
                    <HomeIcon className="h-4 w-4" />
                    </TimelineIcon>
                    <Typography variant="h5" color={!isDone === false ? 'gray' : 'white'} className="flex items-center">
                        Step 1 <Spinner className="ml-1"/>
                    </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                    <Typography color="gary" className="font-normal text-white animate-pulse">
                        Validating items....
                    </Typography>
                </TimelineBody>
            </TimelineItem>
            <TimelineItem>
                <TimelineConnector />
                <TimelineHeader>
                    <TimelineIcon className="p-2">
                        <BellIcon className="h-4 w-4" />
                    </TimelineIcon>
                    <Typography variant="h5" color={isDone === false ? 'gray' : 'white'}>
                        Step 2
                    </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                    <Typography color="gary" className="font-normal text-gray-600">
                        Processing refund....
                    </Typography>
                </TimelineBody>
            </TimelineItem>
            <TimelineItem>
                <TimelineHeader>
                    <TimelineIcon className="p-2">
                        <CurrencyDollarIcon className="h-4 w-4" />
                    </TimelineIcon>
                    <Typography variant="h5" color={isDone === false ? 'gray' : 'white'}>
                        Final Step
                    </Typography>
                </TimelineHeader>
                <TimelineBody>
                    <Typography color="gary" className="font-normal text-gray-600">
                        Refund succesfull!
                    </Typography>
                </TimelineBody>
            </TimelineItem>
        </Timeline>
        </div>
    )
}