import React from 'react'
import Sidebar from '../../../Components/Sidebar'
import FloatBar from '../../../Components/FloatBar'
import networkbg from '../../../assets/networkbg.png'
import { ToastContainer } from 'react-toastify'

const WebScrapers = () => {
  return (
    <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-hidden'
    style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.99), rgba(17, 24, 38, 0.8)), url(${networkbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

      <FloatBar/>
      <div className='flex flex-row gap-2 w-[80%] h-full  '>
      <Sidebar/>
      <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
        <ToastContainer/>
        <div className='w-full h-fit flex justify-between p-4 text-white'>
          <p className=' text-xl'>Tabs</p>
        </div>

        <div className='w-full h-fit flex flex-row gap-8 text-white pl-4'>
          <button className='text-md border-b pb-2 text-gray-300 hover:text-[#22D2EF] hover:border-[#22D2EF] z-10'>2P Brands</button>
          <button className='text-md border-b pb-2 text-gray-300 hover:text-[#22D2EF] hover:border-[#22D2EF] z-10'>3P Brands</button>
        </div>
      </div>
      </div>
    </div>
  )
}

export default WebScrapers



