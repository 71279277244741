import React, { useEffect, useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Tooltip,
} from "@material-tailwind/react"
import { useUser } from "../../../../store/userState"
import { FolderPlusIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid"
import CustomSelect from "./FiltersSelect"

export function GenerateFile({ handleAlterCallBack, isAltereds }) {
    const { user } = useUser()
    const userEmail = user.email

    const [open, setOpen] = useState(false)
    const [dataSource, setDataSource] = useState('')
    const [brandFilters, setBrandFilters] = useState([])
    const [selectedFilter, setSelectedFilter] = useState([])
    const [selectedBrand, setSelectedBrand] = useState('')

    useEffect(() => {
        axios.get('/api/brand-filters/all-filters')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get batches")
                } else {
                    const filters = response.data.result.flatMap(item => item.filters)
                    setBrandFilters(filters)
                    console.log(filters)
                }
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [isAltereds])

    const handleOpen = () => setOpen(!open)

    const handleChange = (event) => {
        setDataSource(event.target.value)
        console.log(event.target.value)
    }

    const generateTimestamp = () => {
        const now = new Date()

        const format = num => String(num).padStart(2, '0')
        return `${now.getFullYear()}${format(now.getMonth() + 1)}${format(now.getDate())}${format(now.getHours())}${format(now.getMinutes())}${format(now.getSeconds())}`
    }

    const addFilter = () => {
        const selectedBrandObject = brandFilters.find(filter => filter.brandName === selectedBrand)

        if (!selectedBrandObject) {
            alert('Selected brand does not exist.')
            return
        }

        const isExistingFilter = selectedFilter.some(filter => filter.brandName === selectedBrandObject.brandName)

        if (isExistingFilter) {
            alert('Filter is already added.')
            return
        }

        setSelectedFilter([...selectedFilter, {
            brandName: selectedBrandObject.brandName,
            skuInitial: selectedBrandObject.skuInitial || '',
            brandTitle: selectedBrandObject.brandTitle || '',
            profileId: selectedBrandObject.profileId || ''
        }])
    }

    const updateFilter = (option) => {
        setSelectedBrand(option)
        console.log('brand filter: ',option)
    }

    const removeFilter = (brandName) => {
        setSelectedFilter(selectedFilter.filter(filter => filter.brandName !== brandName))
    }

    const handleGenerateRequest = () => {

        const validateRequestContent = (content) => {
            return  content.dataSource && content.brandFilters.length > 0 
        }
    
        const requestContent = {
            requestId: generateTimestamp(),
            dataSource: dataSource,
            brandFilters: selectedFilter,
            requestedBy: userEmail
        }
    
        if (!validateRequestContent(requestContent)) {
            window.alert('Make sure all fields are filled out')
            return
        }
    
        axios.post('/api/data-requests/add', requestContent)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`)
                handleAlterCallBack()
                setOpen(!open)
    
                setSelectedBrand('')
                setDataSource('')
                setSelectedFilter([])
            } else {
                toast.error(`${response.data.message}`)
            }
        })
        .catch(error => {
            console.error('Error:', error)
            const errorMessage = error.response?.data?.message || 'An error occurred during add.'
            toast.error(errorMessage)
        })
    }
    
    return (
        <>
            <Button onClick={handleOpen} color="lime" variant="gradient" className="flex items-center">
                <FolderPlusIcon className="h-5 w-5 mr-1" /> Generate File
            </Button>
            <Dialog open={open} handler={handleOpen} className="bg-blue-gray-900" size="lg">
                <DialogHeader className="text-white">File Generator</DialogHeader>
                <DialogBody className="flex flex-col">
                    <div className="flex items-center mb-5">
                        <select
                            id="dropdown-select"
                            onChange={handleChange}
                            className="block w-48 px-3 py-2 text-white bg-gray-800 bg-opacity-40 border border-gray-600 rounded-md shadow-sm cursor-pointer focus:outline-none"
                        >
                            <option selected hidden>Select Source</option>
                            <option className="text-blue-500 bg-gray-800" value="appEagle">App Eagle</option>
                            <option className="text-blue-500 bg-gray-800" value="allListings">All Listings</option>
                        </select>

                        <p className="font-bold ml-5 mr-1 text-white">Add filter</p>

                        <CustomSelect brandFilters={brandFilters} updateFilter={updateFilter} />
                        <PlusCircleIcon onClick={addFilter} className="h-10 w-10 hover:scale-105 hover:rotate-180 transition text-blue-500 cursor-pointer" />
                    </div>

                    <div className="flex text-blue-500 justify-around mb-2 ">
                        <p className="font-bold">Brands</p>
                        <p className="font-bold">SKU Initial</p>
                        <p className="font-bold">Title</p>
                        <p className="font-bold">Profile ID</p>
                    </div>

                    {selectedFilter.map((item, index) => (
                        <div className="w-full h-fit flex flex-row items-center justify-evenly m-1" key={index}>
                            <p className="font-bold text-white">{index + 1}.</p>
                            <input
                                className="px-2 w-80 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                                value={item.brandName}
                                disabled
                                type="text"
                            />
                            <input
                                className="px-2 w-40 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                                value={item.skuInitial}
                                disabled
                                type="text"
                            />
                            <input
                                className="px-2 w-80 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                                value={item.brandTitle}
                                disabled
                                type="text"
                            />
                            <input
                                className="px-2 w-40 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                                value={item.profileId}
                                disabled
                                type="text"
                            />
                            <button 
                                className="text-red-500 font-semibold" 
                                onClick={() => removeFilter(item.brandName)}
                            >
                                <XCircleIcon className="h-6 w-6"/>
                            </button>
                        </div>
                    ))}
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleGenerateRequest}>
                        <span>Submit</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}
