import React from 'react'
import networkbg from '../../assets/networkbg.png'
import FloatBar from '../../Components/FloatBar';
import AdminSidebar from './Components/AdminSidebar';
import DeptToolsAccordion from './Components/DeptToolsAccordion';
import AddDepartments from './Components/AddDepts';
import { ToastContainer } from 'react-toastify';

const ManageDepartmentsView = () => {

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-flex-start items-center flex-col gap-1  relative overflow-hidden text-white'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.99), rgba(17, 24, 38, 0.8)), url(${networkbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
            <FloatBar/>
        <ToastContainer/>
            <div className='flex flex-row w-[95%] h-full bg-opacity-30 p-2'>
                <AdminSidebar />
                <div className='w-full h-full flex items-center flex-col p-1 pl-4 border-l border-[#232B3C]'>
                <div className='w-full h-fit flex flex-row gap-8 text-white mb-2'>
                    <button className='text-md border-b pb-2 text-gray-300 hover:text-[#22D2EF] hover:border-[#22D2EF] z-10'>Department Tools Management</button>
                    <AddDepartments/>
                </div>
                <DeptToolsAccordion />
            </div>
            </div>
            
        </div>
    )
}

export default ManageDepartmentsView