import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useUser } from '../../store/userState'
import { uploadFileToS3 } from '../../middlewares/awsBucket'
import { Button, Spinner, Tooltip, Typography } from '@material-tailwind/react'
import { ArrowUpTrayIcon, BuildingLibraryIcon, CheckIcon, EyeIcon, EyeSlashIcon, LockClosedIcon, LockOpenIcon, PencilSquareIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/solid'

import Sidebar from '../../Components/Sidebar'
import FloatBar from '../../Components/FloatBar'
import neural1 from '../../assets/404neural.webp'

const ProfSettings = () => {

    const navigate = useNavigate()

    const [isDone, setIsDone] = useState(true)
    const [isAltered, setIsAltered] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEditable, setIsEditable] = useState(false)
    const [openResetPw, setOpenResetPw] = useState(false)
    const [jwtToken, setJwtToken] = useState('')
    const [userImgUrl, setUserImgUrl] = useState('')
    const [profImageLink, setProfImageLink] = useState('')
    const [defaultAvatar, setDefaultAvatar] = useState('')

    const [editCredentials, setEditCredentials] = useState({ 
        oldPassword: '', 
        newPassword: '', 
        confirmPassword: '' })

    const [toggleShowPw, setToggleShowPw] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    })

    const { user } = useUser()
    const permission = user.permissions[0].department
    const avatarLink = `https://nexus-portal.s3.amazonaws.com/profileImages/${userImgUrl}`

    const handleEditable = () => {
        setIsEditable(!isEditable)
    }
    const handleOpenResetPw = () => {
        setOpenResetPw(!openResetPw)
        setJwtToken(localStorage.jwt)
    }
    const handleTogglePw = (field) => {
        setToggleShowPw(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }))
    }
    useEffect(() => {
        axios.get(`/api/users/get-user/${user._id}`)
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get users")
                } else {
                    setUserImgUrl(response.data.profileImage)
                    console.log(response.data.profileImage) 
                }
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [isAltered])

    const handleImageUpload =  (event) => {
        const file = event.target.files[0]

        if (file) {
            const reader = new FileReader()

            reader.onloadend = async () => {
            setIsLoading(true)
            setDefaultAvatar(reader.result)
            setProfImageLink(`${user._id}.${file.name}`)
            await uploadFileToS3("profileImages", file, user._id)
            setIsDone(false)
            setIsLoading(false)
            setIsAltered(prevState => !prevState)
            }
            reader.readAsDataURL(file)
        }
    }

    const handleSave = () => {

        axios.put(`/api/users/update-user/${user._id}`, {profileImage: profImageLink})
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`)
                setIsEditable(!isEditable)
                setIsAltered(prevState => !prevState)
                setIsDone(true)
                setIsLoading(true)
            } else {    
                toast.error(`${response.data.message}`)
            }
            setIsLoading(false)
        })
        .catch(error => {
            console.error('Error:', error)
            const errorMessage = error.response?.data?.message || 'An error occurred during add.'
            toast.error(errorMessage)
            setIsLoading(false)
        })
    }

    const triggerFileInput = () => {
        document.getElementById('avatar-upload').click()
    }

    const handleLogout = () => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('id')
        navigate('/login')
    }

    const handleResetPw = () => {

        const userCreds = {
            _id : user.id,
            token: jwtToken,
            oldPassword: editCredentials.oldPassword,
            newPassword: editCredentials.newPassword,
            confirmNewPassword: editCredentials.confirmPassword,
        }
        console.log(userCreds)

        axios.post(`/api/users/auth/change-password`, userCreds)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`)
                console.log('reset password success')
                setEditCredentials({ 
                    oldPassword: '', 
                    newPassword: '', 
                    confirmPassword: '' })
                setOpenResetPw(false)

                if (window.confirm('Password reset successful! Do you want to log out?')) {
                    handleLogout()
                }
            } else {    
                toast.error(`${response.data.message}`)
                console.log(`Error: ${response.data.message}`)
            }
        })
        .catch(error => {
            console.error('Error:', error)
            const errorMessage = error.response?.data?.message || 'Cannot reset password.'
            toast.error(errorMessage)
        })
    }

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1 relative overflow-hidden'
            style={{
                backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.8), rgba(17, 24, 38, 0.7)), url(${neural1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backdropFilter: 'blur(8px)'
            }}>
            <ToastContainer/>
            <FloatBar />
            <div className='flex flex-row gap-2 w-[90%] h-full'>
                <Sidebar />
                <div className='w-full h-[94%] gap-4 flex items-center justify-center bg-opacity-20 p-10'>
                    <div className='w-[100%] h-[100%] bg-[#111827] flex items-center flex-col rounded-lg overflow-hidden'>
                        <div className='h-[30%] w-full bg-indigo-500' style={{
                            backgroundImage: `url(${neural1})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backdropFilter: 'blur(8px)'                          
                        }} />

                        <div className='absolute top-48 flex items-center'>
                            {isEditable ? 
                            <Tooltip content='Upload avatar profile'>
                                <ArrowUpTrayIcon 
                                    className='h-7 w-7 absolute top-36 left-44 z-10 text-blue-500 font-semibold cursor-pointer'
                                    onClick={triggerFileInput} 
                                />
                            </Tooltip>
                            : ''}
                            <input 
                                type="file" 
                                id="avatar-upload" 
                                style={{ display: 'none' }} 
                                onChange={handleImageUpload}
                                accept='.jpg, .png, .jpeg' 
                            />
                            <div className={`border-[10px] h-[12rem] w-[12rem] border-[#111827] bg-gray-300 rounded-full overflow-hidden 
                                `}>
                                <img src={ userImgUrl ? avatarLink : defaultAvatar ? defaultAvatar : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png' } 
                                className='h-full w-full'
                                alt="avatar" />
                            </div>

                            {isEditable ? 
                            <Tooltip content='Image preview'>
                                <div className={`absolute -left-10 top-20  border-[10px] h-[6rem] w-[6rem] border-[#111827] hover:border-blue-400 bg-gray-300 rounded-full overflow-hidden
                                    ${isLoading ? 'animate-pulse' : ''}`}>
                                    <img src={ defaultAvatar ? defaultAvatar : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png' } 
                                    className='h-full w-full'
                                    alt="avatar" />
                                </div> 
                            </Tooltip>
                                : ''}
                            
                            {isEditable ?
                                <div className='absolute left-16 -bottom-0 flex flex-row gap-2'>
                                    <button className={`flex items-center rounded-full bg-green-500 p-1 ${isDone ? 'bg-opacity-50' : ''}`} disabled={isDone} onClick={handleSave}>
                                        {isLoading ? <Spinner className='mr-1 h-5 w-5'/> : <CheckIcon className='h-5 w-5'/>}</button>
                                    <button className='flex items-center rounded-full bg-red-500 p-1' onClick={handleEditable}><XMarkIcon className='h-5 w-5'/></button>
                                </div>
                                :
                                <Tooltip content="Edit Avatar">
                                    <PencilSquareIcon 
                                        className='h-8 w-8 absolute top-36 left-44 text-green-500 font-semibold cursor-pointer' 
                                        onClick={handleEditable} 
                                    />
                                </Tooltip>
                            }
                        </div>

                        <div className='w-[80%] h-fit flex flex-col items-center mt-20'>
                            <Typography color="white" variant="h3">{user.firstName} {user.lastName}</Typography>
                            <Typography variant="h3" className='text-white font-normal mt-4 flex items-center'>
                                <UserIcon className='h-5 w-5 mr-1'/>Role <span className='text-white px-1 rounded-md ml-2 text-xl bg-cyan-400'>{user.role}</span>
                            </Typography>
                            <Typography variant="h3" className='text-white font-normal flex items-center'>
                            <BuildingLibraryIcon className='h-5 w-5 mr-1'/>Department<span className='text-white p-1 rounded-md ml-2 text-xl bg-cyan-400'>{permission}</span>
                            </Typography>
                        </div>

                        <div className='flex flex-col justify-center items-center'>
                            <button onClick={handleOpenResetPw} className='text-white mt-10 font-semibold text-lg flex items-center bg-red-400 rounded-md px-1 hover:scale-110 transition'>Reset Password<LockClosedIcon className='h-5 w-5 ml-1'/></button>
                            {openResetPw ?
                                <form className='flex flex-col gap-1 mt-2 p-2 rounded-md'>
                                    <div className='flex flex-row items-center gap-1 bg-[#222630] rounded-md p-1 px-2'>
                                        <input
                                            type={toggleShowPw.oldPassword ? 'text' : 'password'}
                                            onChange={e => setEditCredentials({ ...editCredentials, oldPassword: e.target.value })}
                                            placeholder='Old password'
                                            className="bg-[#222630] px-4 py-1 outline-none w-[250px] text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]"
                                        />
                                        {toggleShowPw.oldPassword ? 
                                            <EyeSlashIcon onClick={() => handleTogglePw('oldPassword')} className='h-5 w-5 text-white'/> :
                                            <EyeIcon onClick={() => handleTogglePw('oldPassword')} className='h-5 w-5 text-white'/>
                                        }
                                    </div>

                                    <div className='flex flex-row items-center gap-1 bg-[#222630] rounded-md p-1 px-2'>
                                        <input
                                            type={toggleShowPw.newPassword ? 'text' : 'password'}
                                            onChange={e => setEditCredentials({ ...editCredentials, newPassword: e.target.value })}
                                            placeholder='New password'
                                            className="bg-[#222630] px-4 py-1 outline-none w-[250px] text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]"
                                        />
                                        {toggleShowPw.newPassword ? 
                                            <EyeSlashIcon onClick={() => handleTogglePw('newPassword')} className='h-5 w-5 text-white'/> :
                                            <EyeIcon onClick={() => handleTogglePw('newPassword')} className='h-5 w-5 text-white'/>
                                        }
                                    </div>

                                    <div className='flex flex-row items-center gap-1 bg-[#222630] rounded-md p-1 px-2'>
                                        <input
                                            type={toggleShowPw.confirmPassword ? 'text' : 'password'}
                                            onChange={e => setEditCredentials({ ...editCredentials, confirmPassword: e.target.value })}
                                            placeholder='Confirm password'
                                            className="bg-[#222630] px-4 py-1 outline-none w-[250px] text-white rounded-lg border-2 transition-colors duration-100 border-solid focus:border-[#596A95] border-[#2B3040]"
                                        />
                                        {toggleShowPw.confirmPassword ? 
                                            <EyeSlashIcon onClick={() => handleTogglePw('confirmPassword')} className='h-5 w-5 text-white'/> :
                                            <EyeIcon onClick={() => handleTogglePw('confirmPassword')} className='h-5 w-5 text-white'/>
                                        }
                                    </div>
                                    <Button onClick={handleResetPw} variant='gradient' color='blue' className='w-fit mt-2'>Reset</Button>
                                </form>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfSettings
