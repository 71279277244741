import { atom, selector } from "recoil";
import { warehouseUserAct, pricingUserAct, listingsUserAct } from "../middlewares/GlobalUtils/getUserActivityTime";

export const userActivityStateWH = atom({
    key: 'userActivityStateWH',
    default: selector({
        key: 'userActivityStateWHDefault',
        get: async () => {
        const value = await warehouseUserAct()
        return value
        },
    }),
})

export const userActivityStatePrc = atom({
    key: 'userActivityStatePrc',
    default: selector({
        key: 'userActivityStatePrcDefault',
        get: async () => {
        const value = await pricingUserAct()
        return value
        },
    }),
})

export const userActivityStateListings = atom({
    key: 'userActivityStateListings',
    default: selector({
        key: 'userActivityStateListingsDefault',
        get: async () => {
        const value = await listingsUserAct()
        return value
        },
    }),
})