import { atom, useRecoilState } from 'recoil';
// Define an atom to store the user state
export const userState = atom({
  key: 'userState',
  default: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
});

// Custom hook to access and update user state
export const useUser = () => {
  const [user, setUser] = useRecoilState(userState);

  // Update localStorage when user state changes
  const updateUser = newUser => {
    setUser(newUser);
    localStorage.setItem('user', JSON.stringify(newUser));
  };
  return { user, updateUser };
};

