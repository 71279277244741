import React from 'react'
import { CubeTransparentIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import nexus from '../assets/nexusLogo.png'

const FloatBar = () => {
  return (
    <div className='w-full h-[60px] text-white border-b  flex items-center gap-1 justify-start p-2 border-[#232B3C]'>
      <Link to='/home'>
        <div className='flex bg-cover w-[10rem] h-[3rem] p-1 ml-[2rem]'>
          <img src={nexus} className="w-full h-full"/>
        </div>
      </Link>
    </div>
  )
}

export default FloatBar