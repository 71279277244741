import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { ToastContainer } from 'react-toastify'
import { useNavigate, useParams } from "react-router-dom"

import Sidebar from '../../../../Components/Sidebar'
import FloatBar from '../../../../Components/FloatBar'
import neural2 from '../../../../assets/neural-networks.webp'
import { ArrowUturnLeftIcon, TableCellsIcon } from '@heroicons/react/24/solid'
import { ManageReturnsTable } from './ManageReturnsTable'
import { Button, Chip, Tooltip } from '@material-tailwind/react'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import ManageOrdersTable from './ManageOrdersTable'
import ManageInventoryTable from './ManageInventoryTable'

const RefProcessView = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [currentTable, setCurrentTable] = useState('returns')
    const [isHovered, setIsHovered] = useState(false);
    const [category, setCategory] = useState()

    useEffect(() => {
        axios.get(`/api/refund-audits/get/${params.id}`)
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get batches")
                } else {
                    setCategory(response.data.result.categories[0])
                } 
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [])

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
    <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-hidden'
    style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.8)), url(${neural2})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
        <FloatBar/>
        <div className='flex flex-row gap-2 w-[95%] h-full  '>
            <Sidebar/>
            <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
                <ToastContainer/>
                <div className='w-full h-fit flex items-center flex-row gap-2 text-white pl-4 my-3'>
                <ArrowUturnLeftIcon className=' mr-5 h-6 w-6 cursor-pointer hover:scale-125 transition' onClick={handleGoBack}/>
                    <button className='text-md border-b pb-2 text-gray-300 hover:text-[#22D2EF] hover:border-[#22D2EF] z-10'>Refund Actions</button>
                    <Chip variant='gradient' color='purple' value={`Batch ID: ${params.id}`} className='ml-10'/>
                    <Chip variant='gradient' color='teal' value={`Category: ${category}`} className='ml-2'/>
                    {category === 'all' && (
                        <div
                            className='relative'
                            onClick={() => setIsHovered(prevstate => !prevstate)}
                        >   
                            <Tooltip content="Switch table">
                                <AdjustmentsHorizontalIcon className='h-8 w-8 text-blue-500 cursor-pointer' />
                            </Tooltip>
                            {isHovered && (
                                <div className='absolute -top-2 left-10 z-10 w-[12rem] bg-[#123061cb] justify-center text-white p-2 rounded shadow-lg flex flex-col gap-1'>
                                    <button onClick={() => setCurrentTable('returns')} className='hover:bg-blue-500  bg-gray-800 p-1 rounded'>Manage Returns</button>
                                    <button onClick={() => setCurrentTable('orders')} className='hover:bg-blue-500  bg-gray-800 p-1 rounded'>Manage Orders</button>
                                    <button onClick={() => setCurrentTable('inventory')} className='hover:bg-blue-500  bg-gray-800 p-1 rounded'>Manage Inventory</button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className='w-full h-full p-2 pl-4 flex  justify-center m-1 overflow-y-auto overflow-x-auto'>
                    {
                        category === 'all' ? 
                        ( currentTable === 'returns' ? (<ManageReturnsTable batchId={params.id} />) : 
                        currentTable === 'orders' ? (<ManageOrdersTable batchId={params.id} />) : 
                        currentTable === 'inventory' ? (<ManageInventoryTable batchId={params.id} /> ) : 
                        ('')
                        ) : ('')
                    }
                </div>
            </div>
        </div>
    </div>
    )
}

export default RefProcessView
