import axios from 'axios'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

const exportCouponXlsx = async (batchId) => {
    try {
        const response = await axios.get(`https://api-central.outdoorequippedservice.com/api/v1/coupon-promotions/promotions/batch-content/${batchId}`)
        const couponDetails = response.data
        
        console.log(couponDetails)

        const prodDetails = []

        const productList = { 
            header: [
                "Promotion ID", "Title", "ASIN", "Stock", "Supressed", 
                "Suppression Type", "Suppression Threshold Source", "Suppression Threshold Name", 
                "Required Value", "Existing Value", "Suppression Type2", 
                "Suppression Threshold Source2", "Suppression Threshold Name2", "Required Value2", 
                "Existing Value2", "Error Message"
            ], 
            data: prodDetails, 
            sheetName: "Promotion Products"
        }

        const productDeets = couponDetails[0]?.productList[0]?.couponPromotionProducts || []
        const promoId = couponDetails[0]?.promotionId || ''
        const promoTitle = couponDetails[0]?.promotionDetails[0]?.title || ''

        productDeets.forEach(item => {
            const suppDetails1 = item?.suppressionDetails[0]
            const suppDetails2 = item?.suppressionDetails[1]

            prodDetails.push({
                "Promotion ID": promoId || '',
                "Title": promoTitle || '',
                "ASIN": item?.asin || '',
                "Stock": item?.inventory || '',
                "Supressed": item?.isSuppressed || '',
                "Suppression Type": suppDetails1?.suppressionType || '',
                "Suppression Threshold Source": suppDetails1?.suppressionThresholdSource || '',
                "Suppression Threshold Name": suppDetails1?.suppressionThresholdName || '',
                "Required Value": suppDetails1?.requiredValue || '',
                "Existing Value": suppDetails1?.existingValue || '',
                "Suppression Type2": suppDetails2?.suppressionType || '',
                "Suppression Threshold Source2":  suppDetails2?.suppressionThresholdSource || '',
                "Suppression Threshold Name2": suppDetails2?.suppressionThresholdName || '',
                "Required Value2": suppDetails2?.requiredValue || '',
                "Existing Value2": suppDetails2?.existingValue || '',
                "Error Message": item?.errorText || ''
            })
        })

        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.json_to_sheet(productList.data, { header: productList.header })
        XLSX.utils.book_append_sheet(wb, ws, productList.sheetName)

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([wbout], { type: 'application/octet-stream' })
        saveAs(blob, `cp-${batchId}-${promoTitle}.xlsx`)

    } catch(err) {
        console.error(err)
    }
}

export default exportCouponXlsx
