import React from 'react';

const RefDropdown = ({ itemId, onSelectChange }) => {
    const handleChange = (event) => {
        const { value } = event.target;
        onSelectChange(itemId, value);
    };

    return (
        <select
            id="dropdown-select"
            className="block w-48 px-3 py-2 mt-1 text-white bg-gray-800 bg-opacity-40 border border-gray-600 rounded-md shadow-sm cursor-pointer"
            onChange={handleChange}
        >
            <option className='text-blue-500 bg-gray-800' value="regRef">Regular Refund</option>
            <option className='text-blue-500 bg-gray-800' value="noNeedRef">No need Refund</option>
            <option className='text-blue-500 bg-gray-800' value="standRef">Standard Refund</option>
            <option className='text-blue-500 bg-gray-800' value="dollarRef">$1 Refund</option>
            <option className='text-blue-500 bg-gray-800' value="outboundRef">Outbound Shipping</option>
            <option className='text-blue-500 bg-gray-800' value="lessRef">Less Shipping</option>
            <option className='text-blue-500 bg-gray-800' value="resFeeRef">Restocking Fee Refund</option>

        </select>
    );
};

export default RefDropdown;
