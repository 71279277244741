const getLastUserAct = (date) => {
    const now = new Date()
    const res = new Date(date)
    const seconds = Math.floor((now - res) / 1000)
    const minutes = Math.floor(seconds / 60)
    
    if (minutes >= 1440) { 
        const days = Math.floor(minutes / 1440)
        if (days >= 30) {
            const months = Math.floor(days / 30)
            return `${months} ${months > 1 ? 'months' : 'month'} ago`
        }
        return `${days} ${days > 1 ? 'days' : 'day'} ago`
    }
    
    if (minutes >= 60) { 
        const hours = Math.floor(minutes / 60)
        return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`
    }
    
    return `${Math.max(minutes, 0)} ${minutes > 1 ? 'minutes' : 'minute'} ago`
}

export default getLastUserAct