import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { useUser } from '../../../store/userState';

import { Card, Checkbox, Chip, Tooltip, Typography } from "@material-tailwind/react";
import Pagination from "../../../Components/Pagination";
import { ArchiveBoxXMarkIcon, ArrowDownTrayIcon, ChevronUpDownIcon, DocumentCheckIcon, DocumentMinusIcon, InformationCircleIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { PopViewThread } from "./PopViewThread";
import SearchInput from "../../../Components/SearchInput";
import DropdownSelect from '../../../Components/DropdownSelect';

const TABLE_HEAD = ["Creation Date", "Updated Date", "Case ID", "Status","Primary Email", "Short Description", "SAS Sent", "Actions"];

export default function AmzCasesTable({ isAltereds }) {
    const [cases, setCases] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [isAltered, setIsAltered] = useState(false)
    const [countdown, setCountdown] = useState(60)
    const [caseList, setCaseList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")
    const [isMultipleUpdate, setIsMultipleUpdate] = useState(false)
    const [delMulCase, setDelMulCase] = useState([])
    const [checkedCases, setCheckedCases] = useState({});
    const [selectedValue, setSelectedValue] = useState('');

    const { user } = useUser();
    const userEmail = user.email;
    
    const casesPerPage = 10;
    const indexOfLastCase = currentPage * casesPerPage;
    const indexOfFirstCase = indexOfLastCase - casesPerPage;
    
    const filteredCases = cases.filter(item =>
        (item.caseId && item.caseId.toString().includes(searchTerm.trim())) ||
        (item.descriptions && item.descriptions.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.email && item.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.addedBy && item.addedBy.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    const sortedCases = [...filteredCases].sort((a, b) => {
        const isAEmpty = !a.latestDate || !a.dateCreated || !a.status;
        const isBEmpty = !b.latestDate || !b.dateCreated || !b.status;
    
        if (isAEmpty && !isBEmpty) {
            return -1; // a comes first
        } else if (!isAEmpty && isBEmpty) {
            return 1; // b comes first
        }
    
        if (selectedValue === 'recentlyUpdated') {
            return new Date(b.latestDate) - new Date(a.latestDate);
        }
        return 0;
    });
    
    const filteredAndSortedCases = selectedValue === 'myCases'
        ? sortedCases.filter(item => item.addedBy && item.addedBy.toLowerCase() === userEmail.toLowerCase())
        : sortedCases;
    
    const currentCases = filteredAndSortedCases.slice(indexOfFirstCase, indexOfLastCase);

    useEffect(() => {
        const toggleInterval = setInterval(() => {
            setIsAltered(prevState => !prevState);
            setCountdown(60); 
        }, 60000); 

        const countdownInterval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000); 

        return () => {
            clearInterval(toggleInterval);
            clearInterval(countdownInterval);
        };
    }, []);

    useEffect(() => {
        setIsLoad(true);

        axios.get('/api/amz-cases/get')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get cases");
                } else {
                    setCaseList(response.data.result);
                    let caseIds = [];
                    response.data.result.map(item => caseIds.push(item.caseId));
                    axios.post('https://api-central.outdoorequippedservice.com/api/v1/amazon-cases/summary/multiple', { caseIds })
                        .then(res => setCases(res.data));
                };
                setIsLoad(false);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [isAltered, isAltereds]);

    const handleUpdateCase = (caseId, sasSent) => {
        console.log('sas', caseId, sasSent)

        const updateCase = {
            sasSent: !sasSent
        }

        axios.put(`/api/amz-cases/update/${caseId}`, updateCase)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`);
                setIsAltered(prevState => !prevState);
            } else {
                toast.error(`${response.data.message}`);
                console.log('Error:', response.data.error);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });

    }

    const handleDeleteCase = (caseId) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete this case ${caseId}?`);

        if (isConfirmed) {
            axios.delete(`/api/amz-cases/delete/${caseId}`)
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`);
                        setIsAltered(prevState => !prevState);
                    } else {
                        toast.error(`${response.data.message}`);
                        console.log('Error:', response.data.error);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    const toggleMultipleDelete = () => {setIsMultipleUpdate(!isMultipleUpdate)}

    const cancelMultipleDelete = () => {
        setIsMultipleUpdate(!isMultipleUpdate) 
        setDelMulCase([])
        setCheckedCases({})
    }

    const storeMultipleCaseToDel = (caseId, isChecked) => {
        setDelMulCase((prevCases) => {
            if (isChecked) {
                return [...prevCases, caseId];
            } else {
                // Remove caseId from the array
                return prevCases.filter((id) => id !== caseId);
            }
        });
        setCheckedCases((prevCheckedCases) => ({
            ...prevCheckedCases,
            [caseId]: isChecked,
        }));
    }
    useEffect(() => {
        console.log('multiple case:', delMulCase);
    }, [delMulCase]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleDeleteCases = () => {
        const isConfirmed = window.confirm(`Are you sure you want to delete these cases?`);

        const deleteMultipleCases = {
            ids: delMulCase
        }
        console.log(deleteMultipleCases)

        if (isConfirmed) {
            axios.post(`/api/amz-cases/delete`, deleteMultipleCases)
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`);
                        setIsMultipleUpdate(!isMultipleUpdate)
                        setIsAltered(prevState => !prevState);
                        console.log('Deleted cases:', delMulCase)
                    } else {
                        toast.error(`${response.data.message}`);
                        console.log('Error:', response.data.error);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    const exportToCsv = () => {
        const headers = ["Creation Date", "Updated Date", "Case ID", "Status", "Primary Email", "Short Description"];
        
        const filteredCases = cases.filter(({ status, latestDate, caseId }) => {

            const sasSent = caseList.find((item) => item.caseId === caseId).sasSent

            if (sasSent === true) return false
            if (status !== 'Resolved') return false;
            const updatedDate = new Date(latestDate);
            if (isNaN(updatedDate.getTime())) return false;
            const currentDate = new Date();
            const timeDifference = currentDate.getTime() - updatedDate.getTime();
            const daysDifference = timeDifference / (1000 * 3600 * 24);
            return daysDifference >= 5;
        });
        
        const data = filteredCases.map(({ dateCreated, latestDate, caseId, status, email, descriptions }) => ({
            "Creation Date": dateCreated,
            "Updated Date": latestDate,
            "Case ID": caseId,
            "Status": status,
            "Primary Email": email,
            "Short Description": descriptions
        }));
        
        const csv = unparse({ fields: headers, data });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'resolved_cases_5_days_old_or_beyond.csv');
    };

    const handleSelectChange = (value) => {
        setSelectedValue(value);
        console.log(selectedValue)

    };

    return (
        <div className="flex flex-col w-full h-full">
            <div className='flex items-center justify-between text-white'>
                <div className='flex items-center'>                
                    <DropdownSelect  onSelectChange={handleSelectChange}/>
                </div>
                <SearchInput value={searchTerm} onChange={handleSearch}/>

                <div className='flex items-center'>
                <Tooltip content="Export resolved cases(5 days or over)">
                        <button
                            className="cursor-pointer flex justify-between bg-[#36455e] mr-2 px-2 py-1 rounded-full text-white tracking-wider shadow-xl hover:bg-gray-900 hover:scale-105 duration-500 hover:ring-1 w-[130px]"
                            onClick={exportToCsv}
                        >
                            Export file
                            <ArrowDownTrayIcon className='w-5 h-5'/>
                        </button> 
                    </Tooltip>
                    <p className="m-3">Monitored Cases: <strong className='text-blue-400'>{cases.length}</strong></p>

                    <div className='flex items-center p-2 flex-row justify-between animate-pulse'>Auto Refresh &nbsp; {countdown}</div>
                    <div class="three-body">
                        <div class="three-body__dot"></div>
                        <div class="three-body__dot"></div>
                        <div class="three-body__dot"></div>
                    </div>
                    {!isMultipleUpdate ? 
                    <Tooltip content="Delete Multiple">
                    <PencilSquareIcon className='h-6 w-6 ml-[2rem] cursor-pointer hover:scale-125 transition text-blue-500'
                    onClick={toggleMultipleDelete}/>
                </Tooltip>
                    : 
                    <div className='flex'>
                        <Tooltip content="Delete selected cases">
                            <ArchiveBoxXMarkIcon className='h-6 w-6 ml-[2rem] cursor-pointer hover:scale-125 transition text-green-500' onClick={handleDeleteCases}/>
                        </Tooltip>
                        <Tooltip content="Cancel">
                            <XMarkIcon className='h-6 w-6 ml-[1rem] cursor-pointer hover:scale-125 transition text-red-500' onClick={cancelMultipleDelete}/>
                        </Tooltip>
                        
                    </div>}

                </div>
            </div>

            <Card className="min-h-fit max-h-[37rem] min-w-full max-w-[60rem] bg-opacity-70 bg-[#101927] rounded-b-none overflow-y-auto">
                <table className="w-full min-w-max table-auto text-center ">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-b border-[#374050] bg-[#1E2936] bg-opacity-90 p-4 sticky top-0 z-10">
                                        <Typography
                                            variant="small"
                                            color="white"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    { cases.length <= 0 ? (
                        <>
                        {[...Array(9)].map((_, index) => (
                            <tr key={index} className='even:bg-blue-gray-700 animate-pulse '>
                                <td colSpan="8" className="p-4 animate-pulse">
                                    {/* Skeleton loader */}
                                    <div className="h-[2rem] rounded"></div>
                                </td>
                            </tr>
                        ))}
                        </>
                        ) :
                        (currentCases.map(({ caseId, status, email, latestDate, dateCreated, descriptions, addedBy }, index) => {
                            const sasSent = caseList.find((item) => item.caseId === caseId)?.sasSent ?? false
                            const globalIndex = indexOfFirstCase + index;
                            return (
                                <tr key={caseId} className="even:bg-blue-gray-700 hover:bg-blue-900">
                                    <td className="p-4">
                                        <Typography variant="small" color="white" className="font-normal">
                                            {<div className="flex flex-col">
                                                <p>{dateCreated && dateCreated.split(',')[0]}</p>
                                                {dateCreated && dateCreated.split(',')[1]} 
                                            </div>                                                                                     
                                            || <p className='text-3xl font-bold animate-pulse'>.....</p>}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="white" className="font-normal">
                                            {<div className="flex flex-col">
                                                <p>{latestDate && latestDate.split(',')[0]}</p>
                                                {latestDate && latestDate.split(',')[1]} 
                                            </div>                                                                                     
                                            || <p className='text-3xl font-bold animate-pulse'>.....</p>}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="white" className="font-normal flex items-center">
                                            {caseId} 
                                            {addedBy === null ? '' : <Tooltip content={`added by: ${addedBy}`}><InformationCircleIcon className='h-5 w-5 ml-2'/></Tooltip>}                                         
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="white" className="font-medium">
                                            {status || <p className='text-3xl font-bold animate-pulse'>.....</p>}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="white" className="font-medium">
                                            {email || <p className='text-3xl font-bold animate-pulse'>.....</p>}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Typography variant="small" color="white" className="font-medium">
                                        {descriptions ? (
                                            descriptions.length > 45 ? `${descriptions.substring(0, 45)}...` : descriptions
                                        ) : (
                                            <p className='text-3xl font-bold animate-pulse'>.....</p>
                                        )}
                                        </Typography>
                                    </td>
                                    <td className="p-4">
                                        <Chip
                                            value={sasSent ? 'Yes' : 'No'}
                                            variant="gradient"
                                            color={`${sasSent ? 'green' : 'red'}`}
                                            className="rounded-full font-medium shadow-xl"/>
                                    </td>
                                    <td className="p-4 text-white">
                                        <PopViewThread caseId={caseId} shortDesc={descriptions}/>
                                        <Tooltip content="SAS sent status">
                                            <button className={`rounded-lg p-1 m-1 px-2 ${!sasSent ? 'bg-green-500 ' : 'bg-orange-500 '}`} onClick={() => handleUpdateCase(caseId, sasSent)}>                                               
                                                {!sasSent ? <DocumentCheckIcon className="h-5 w-5" /> : <DocumentMinusIcon className="h-5 w-5" />}
                                            </button>                      
                                        </Tooltip>
                                        {isMultipleUpdate ? 
                                        <Checkbox color='amber' 
                                        checked={!!checkedCases[caseId]}
                                        key={caseId}
                                        onChange={(e) => storeMultipleCaseToDel(caseId, e.target.checked)}/> 
                                        : 
                                        <Tooltip content="Remove case">
                                            <button className="bg-red-500 rounded-lg p-1 m-1 px-2" onClick={() => handleDeleteCase(caseId)}>
                                                <TrashIcon className="h-5 w-5" />
                                            </button>                      
                                        </Tooltip>
                                        }

                                    </td>
                                </tr>
                            );
                        }))
                    }
                    </tbody>
                </table>
            </Card>
            <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    currentPage={currentPage}
                    customClass={`bg-opacity-50`}
                    setCurrentPage={setCurrentPage}
                    totalPages={Math.ceil(filteredCases.length / casesPerPage)}
                />
            </div>
        </div>
    );
}