import React from 'react';

const DropdownSelect = ({ onSelectChange }) => {
    const handleChange = (event) => {
        onSelectChange(event.target.value);
    };

    return (
        <>
            <label htmlFor="dropdown-select" className="block text-sm font-medium text-white mr-2">
                Filter by:
            </label>
            <select
                id="dropdown-select"
                className="block w-48 px-3 py-2 mt-1 text-white bg-gray-800 bg-opacity-40 border border-gray-600 rounded-md shadow-sm"
                onChange={handleChange}
            >
                <option default selected value="allCases">All cases</option>
                <option value="myCases">My cases</option>
                <option value="recentlyUpdated">Recently updated</option>                        
            </select>
        </>
    );
};

export default DropdownSelect;
