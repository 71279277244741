import React, { useState } from 'react'
import axios from 'axios'

import { ArrowPathIcon, ArrowUturnLeftIcon, CubeTransparentIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import neural1 from '../../assets/404neural.webp'

const ResetPW = () => {

    const navigate = useNavigate()
    const [userCredentials, setUserCredentials] = useState({_id: '', token: '', oldPassword: '', newPassword: '', confirmNewPassword: ''}) 
    const [isLoad, setIsLoad] = useState(false)

    const handleLogin = () => {
        setIsLoad(true);
        
        axios.post('http://localhost:5000/api/users/auth/login', userCredentials)
            .then(response => {
                if (response.data.message) {
                    console.log("Reser failed!");         
                } else {
                    console.log("login: ", response.data)
                    navigate(response.data.user.role === 'Admin' ? "/adminNXS0011/dashboard" : "/home");
                }
                setIsLoad(false);
            })
            .catch(error => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || 'An error occurred during login.';
                toast.error(errorMessage);
            });
        };

    return (
        <>
        <ToastContainer />
        <div className='w-screen h-screen  bg-[#111926] flex justify-items-start items-center flex-col gap-1  relative overflow-hidden'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.6)), url(${neural1})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
            
            <div className='flex items-center justify-center text-white w-full h-fit border-b border-[#232B3C] mb-[5rem]'>
                <p className='ml-20 text-[3rem] text-[#22D2EF] font-bold'>NE<span className='text-white'>X</span>US</p>
                <CubeTransparentIcon className='w-10 h-10 animate-pulse'/>
            </div>
        
            <div className="w-[30%] h-fit border-b-2 border-l border-[#22D2EF] overflow-hidden shadow-xl bg-opacity-40 bg-black p-10 rounded-lg before:w-24 before:h-24 before:absolute before:bg-purple-600 
            before:rounded-full before:-z-10 before:blur-2xl after:w-32 after:h-32 after:absolute after:bg-sky-400 after:rounded-full after:-z-10 after:blur-xl after:top-24 after:-right-12">
                <Link to="/login">
                    <button className="text-md font-bold text-gray-400 hover:text-gray-200  flex items-center mb-2">
                        <ArrowUturnLeftIcon className='h-5 w-5 mr-2'/>Back to login
                    </button>
                </Link>
                <h2 className="text-2xl font-bold text-white mb-6">Reset password</h2>

                <div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-300">User email</label>
                        <input
                        id="oldPassword"
                        className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                        type="text"
                        onChange={e => setUserCredentials ({...userCredentials, _id: e.target.value})}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-300">Old password</label>
                        <input
                        id="oldPassword"
                        className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                        type="text"
                        onChange={e => setUserCredentials ({...userCredentials, oldPassword: e.target.value})}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-300">New password</label>
                        <input
                        id="newPassword"
                        className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                        name="password"
                        type="password"
                        onChange={e => setUserCredentials ({...userCredentials, newPassword: e.target.value})}
                        />
                    </div>

                    <div className="mb-4">
                        <label  className="block text-sm font-medium text-gray-300">Confirm new password</label>
                        <input
                        id="confirmNewPassword"
                        className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                        name="text"
                        type="text"
                        onChange={e => setUserCredentials ({...userCredentials, confirmNewPassword: e.target.value})}
                        />
                    </div>

                    <div className="flex justify-between">
                        <button
                        className="bg-[#22D2EF] text-white px-4 py-2 w-fit font-bold rounded-md hover:bg-opacity-80"
                        type="submit"
                        onClick={handleLogin}
                        >
                            {isLoad ? (
                                <div className="flex gap-1 items-center">                     
                                <span className="ml-1">Change password</span>
                                <ArrowPathIcon className="h-5 w-5 animate-spin" />
                                </div>
                            ) : (
                                'Change password'
                            )}
                        </button>
                    </div>
                </div>
            </div>

        </div>
        </>

    )
}

export default ResetPW