import { useEffect, useState } from 'react'
import axios from 'axios'
import {  Card, Tooltip, Typography } from "@material-tailwind/react"
import Pagination from "../../../../Components/Pagination"
import { CheckBadgeIcon, TableCellsIcon } from '@heroicons/react/24/solid'
import { ChatBubbleBottomCenterTextIcon, XCircleIcon } from '@heroicons/react/24/outline'
import SearchInput from '../../../../Components/SearchInput'

const TABLE_HEAD = [ "UPC", "SKU1", "SKU2" ]

const ManageInventoryTable = ({ batchId }) => {

    const [orderMap, setOrderMap] = useState({});
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [flattenedBatchList, setFlattenedBatchList] = useState({})

    useEffect(() => {
        const fetchBatchData = async () => {
            try {
                const [nexResponse, acResponse] = await Promise.all([
                    axios.get(`/api/refund-audits/get/${batchId}`),
                    axios.get(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/batch-content/${batchId}`)
                ])
    
                if (nexResponse.data.message || acResponse.data.message) {
                    console.log("Cannot get batches")
                } else {
                    const nexData = nexResponse.data.result.batchContent
                    const acData = acResponse.data[0].batchDetails
    
                    const tempBatchList = []

                    const tempOrderMap = {}
                    nexData.forEach((batch, index) => {
                        tempOrderMap[batch.orderId] = index
                    })
                    setOrderMap(tempOrderMap)

                    console.log(acData)
                    
                    nexData.forEach(batch => {
                        const acBatch = acData?.find(item => item._id === batch._id)
                        if (acBatch && acBatch?.manageInventoryData.length > 0) {
                                        const upc = batch?.upc || '....'
                                        const sku1 = acBatch?.manageInventoryData?.[0]?.sku || '....' 
                                        const sku2 = acBatch?.manageInventoryData?.[1]?.sku || '....'

                                        tempBatchList.push({ upc, sku1, sku2 });
                        }
                    })
                    setFlattenedBatchList(tempBatchList)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }
        fetchBatchData()
    }, [])

    const flattenedBatchArray = Object.entries(flattenedBatchList).map(([orderId, batch]) => ({
        orderId,
        ...batch
    })).sort((a, b) => orderMap[a.orderId] - orderMap[b.orderId]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1)
    }

    const batchesPerPage = 15
    const totalPages = Math.ceil(flattenedBatchArray.length / batchesPerPage)
    const indexOfLastBatch = currentPage * batchesPerPage
    const indexOfFirstBatch = indexOfLastBatch - batchesPerPage

    const filteredBatches = flattenedBatchArray.filter(item =>
        (item.upc && item.upc.toString().includes(searchTerm.trim())) ||
        (item.sku1 && item.sku1.toString().includes(searchTerm.trim())) ||
        (item.sku2 && item.sku2.toLowerCase().includes(searchTerm.trim())) 
    )

    const batchData = filteredBatches.slice(indexOfFirstBatch, indexOfLastBatch)

    return (
        <div className="flex flex-col min-h-full max-h-[48rem] w-full rounded-t-md overflow-hidden">
                <div className="flex items-center justify-between w-full">
                    <SearchInput  value={searchTerm} onChange={handleSearch}/>
                    <p className='text-white font-bold text-xl flex items-center'><TableCellsIcon className='h-7 w-7 mr-1'/>Manage Inventory</p>
                    <div className='flex items-center'>
                        <p className="font-semibold mr-5 text-white">Total Items: <span className="text-blue-500">{flattenedBatchArray.length}</span></p>
                    </div>
                </div>
            <Card className="h-full min-w-full max-w-[90.4rem] bg-[#101927] bg-opacity-70 rounded-b-none overflow-y-auto">
                <table className="w-full min-w-max table-auto text-center">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th key={head} className={`border-b border-[#374050] bg-[#1E2936] bg-opacity-90 p-4 sticky top-0 z-10
                                    ${head === 'Order Id' ? 'left-0 z-20' : ''}`}>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="font-semibold leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {flattenedBatchArray.length <= 0 ?
                            <>
                                {[...Array(15)].map((_, index) => (
                                    <tr key={index} className='border-y bg-blue-gray-600 border-[#374050] animate-pulse'>
                                        <td colSpan="13" className="p-4">
                                            <div className="h-[2rem] rounded"></div>
                                        </td>
                                    </tr>
                                ))}
                            </> :
                                (batchData.map((item) => (
                                    <tr key={item.rmaId} className="border-y border-[#374050] hover:bg-blue-900">
                                        <td className="p-4 sticky left-0 z-5 bg-[#101927]">
                                            <Typography variant="small" color="white" className="font-bold">
                                                {item.upc}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {item.sku1}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {item.sku2}
                                            </Typography>
                                        </td>
                                    </tr>
                                )))         
                            }
                    </tbody>
                </table>
            </Card>
            <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    currentPage={currentPage}
                    customClass={`bg-opacity-50`}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                />
            </div>
        </div>
    )
}

export default ManageInventoryTable
