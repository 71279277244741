import React, { useState, useEffect, useRef } from "react";
import { useUser } from '../../../store/userState'
import axios from "axios";
import { toast} from 'react-toastify'
import * as XLSX from 'xlsx'

import { Button, Dialog, DialogHeader, DialogBody, DialogFooter,Typography, Checkbox, Tooltip, Chip, } from "@material-tailwind/react";
import { ArrowUpTrayIcon, DocumentChartBarIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import SampleDownloadButton from "./SampleDownloadButton";

export function AddTransacts({ onAlterState }) {
    const { user } = useUser();
    const userEmail = user.email
    const fileInputRef = useRef(null);
    
    const [open, setOpen] = useState(false);
    const [fileName, setFileName] = useState("");
    const [selectedType, setSelectedType] = useState([]);
    const [importData, setImportData] = useState([]);
    const [importErrMessage, setImportErrMessage] = useState('');

    const [checkboxState, setCheckboxState] = useState({
        all: false,
        manageInventory: false,
        manageOrders: false,
        manageReturns: false,
    });

    const handleOpen = () => setOpen(!open);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            console.log('Selected file:', file);

            const reader = new FileReader();
            reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const headers = ["upc", "orderId"]; 
            const rows = jsonData.slice(1); 

            const formattedData = [];
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (!row || row.length === 0) continue;
                const rowObject = {};
                headers.forEach((header, index) => {
                    rowObject[header] = row[index];
                });
                formattedData.push(rowObject);
            }
            
            setImportData(formattedData);
            setImportErrMessage('');
            fileInputRef.current.value = '';
            };
            reader.readAsArrayBuffer(file);
        }
    };

        useEffect(() => {
            if (importData.length > 0) {
                console.log('Parsed data:', importData);
            }
            }, [importData]);

            const handleCheckboxChange = (event) => {
                const { id, checked } = event.target;
                setCheckboxState((prevState) => {
                    const newState = { ...prevState, [id]: checked };
                    if (id === "all") {
                    newState.manageInventory = checked;
                    newState.manageOrders = checked;
                    newState.manageReturns = checked;
                    setSelectedType(checked ? ["all"] : []);
                    } else {
                    newState.all = newState.manageInventory && newState.manageOrders && newState.manageReturns;
                    if (newState.all) {
                        setSelectedType(["all"]);
                    } else {
                        if (checked) {
                        setSelectedType((prevSelectedType) => [...prevSelectedType, id]);
                        } else {
                        setSelectedType((prevSelectedType) => prevSelectedType.filter(type => type !== id));
                        }
                    }
                    }
                    console.log('Checkbox State:', newState); // Log the updated state
                    return newState;
                });
                };

    const generateTimestamp = () => {
        const now = new Date();
        
        const format = num => String(num).padStart(2, '0');
        return `${now.getFullYear()}${format(now.getMonth() + 1)}${format(now.getDate())}${format(now.getHours())}${format(now.getMinutes())}${format(now.getSeconds())}`;
    };

    const handleAddBatch = () => {

        const validateImportData = (selectedTypes, importData) => {
            if (selectedTypes.length === 0) {
                return 'No data source selected. Please select at least one .';
            }
            if (importData.length === 0) {
                return 'No import data provided. Please provide the data to import.';
            }

            const upc = importData.map((object) => object.upc);
            const orderId = importData.map((object) => object.orderId);

            let upcVal = upc.indexOf(undefined) 
            let orderIdVal = orderId.indexOf(undefined) 

            const manageInvType = selectedTypes.includes('manageInventory')
            const manageOrdersType = selectedTypes.includes('manageOrders')
            const manageReturnsType = selectedTypes.includes('manageReturns')

            if (selectedTypes.includes('all')) {
                if ((upcVal !== -1 || orderIdVal !== -1) || (upc.length === 0 && orderId.length === 0)) {
                    setImportData([])
                    console.log(importData)
                    return (upc.length && orderId.length) === 0 ?  `Order Id & UPC must be provided to generate.` : `There are empty cells at UPC or Order ID column`              
                }
            }
            if (manageInvType && (manageOrdersType || manageReturnsType)) {
                if ((upcVal !== -1 || orderIdVal !== -1) || (upc.length === 0 && orderId.length === 0)) {
                    setImportData([]);
                    return (upc.length && orderId.length) === 0 ?  `Order Id & UPC must be provided to generate.` : `There are empty cells at UPC or Order ID column`              
                }
            }
            if (manageOrdersType || manageReturnsType) {
                if (orderIdVal !== -1 || upc.length === 0){
                    setImportData([]);
                    return orderId.length === 0 ?  `Order Id must be provided to generate.` : `There are empty cells Order ID column`              
                }
            }
            if (manageInvType) {
                if (upcVal !== -1 || upc.length === 0) {
                    setImportData([]);
                    return upc.length === 0 ? 'UPC must be provided to generate.' : 'There are empty row/s at UPC column'
                }
            }
            return null;
        }
    
        const errorMessage = validateImportData(selectedType, importData);
        if (errorMessage) {
            console.log(errorMessage);
            setImportErrMessage(errorMessage)
            return;
        }
    
        const batchImportData = {
            batchId: generateTimestamp(),
            addedBy: userEmail,
            categories: selectedType,
            batchContent: importData
        }
    
        axios.post('/api/refund-audits/add', batchImportData)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`);
                onAlterState()
                setOpen(!open)
                setFileName("");
                setImportData([]);
                setSelectedType([]);
                setImportErrMessage("")
                setCheckboxState({
                    all: false,
                    manageInventory: false,
                    manageOrders: false,
                    manageReturns: false,
                });
            } else {
                toast.error(`${response.data.message}`)
            }
        })
        .catch(error => {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred during add.';
            toast.error(errorMessage);
        });
    }

    const tblHeaders = ["Data Sources", "Required Parameters", "Request Response"]

    const TABLE_ROWS = [
        {
            id: "all",
            name: "All",
            param: "UPC",
            reqResponse: "All",
        },
        {
            id: "manageInventory",
            name: "Manage Inventory",
            param: "UPC",
            reqResponse: "SKU1, SKU2",
        },
        {
            id: "manageOrders",
            name: "Manage Orders",
            param: "Order Id",
            reqResponse: "Tracking ID, SKU, Item Total",
        },
        {
            id: "manageReturns",
            name: "Manage Returns",
            param: "Order Id",
            reqResponse: "Order ID, RMA ID, SKU, Outbound Shipping, Return Reason, Buyer Comments, Gift Badge, Replacement Badge, Amazon Refunded, Manually Refunded, Refund Total Amount"
        }
        ];

    return (
        <>
            <Button onClick={handleOpen} color="blue" variant="gradient">Import</Button>
            <Dialog open={open} handler={handleOpen} className="">
                <DialogHeader className="bg-blue-500 rounded-t-md">
                    <Typography variant="h5" color="white">
                        Generate Data
                    </Typography>
                    {importErrMessage && <p className="flex items-center font-bold text-white bg-red-500 p-1 rounded-md text-sm ml-20 animate-pulse">
                        <ExclamationTriangleIcon className="mr-1 h-5 w-5 text-yellow-500"/>Error: {importErrMessage}</p> }
                </DialogHeader>
                <DialogBody divider className="grid place-items-center gap-4">
                <SampleDownloadButton/>
                    <div className="py-[2rem] px-[10rem] flex flex-col items-center justify-center outline-dotted rounded-md">
                        <label htmlFor="file-upload" className="font-bold text-blue-500 cursor-pointer flex items-center">
                            Import lookup file <ArrowUpTrayIcon className="ml-2 h-5 w-5" />
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            className="hidden"
                            onChange={handleFileUpload}
                            ref={fileInputRef}
                        />
                        {fileName && <p className="mt-2 text-sm text-gray-500">Selected file: {fileName}</p>}
                    </div>

                    <table className="w-full min-w-max table-auto text-left">
                        <thead>
                        <tr>
                            {tblHeaders.map((head) => (
                            <th key={head} className="border-b bg-blue-gray-50 p-4  border-2 border-gray-300 text-center">
                                <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                                >
                                {head}
                                </Typography>
                            </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {TABLE_ROWS.map(({ id, name, param, reqResponse }) =>  {
                            const isChecked = name === 'Manage Returns' ? checkboxState.manageReturns : 
                                            name === 'Manage Inventory' ? checkboxState.manageInventory :
                                            name === 'Manage Orders' ? checkboxState.manageOrders : 
                                            checkboxState.all;

                            return (
                                <tr key={name} className={`${isChecked ? 'bg-blue-300 text-white' : 'even:bg-blue-gray-50/50'} border-2 border-gray-300 `}>
                                <td className="p-4">
                                <Checkbox
                                    id={id}
                                    label={
                                        <Typography className="flex font-normal text-sm text-black">
                                            {name}
                                        </Typography>
                                    }
                                    ripple={true}
                                    checked={name === 'Manage Returns' ? checkboxState.manageReturns : name === 'Manage Inventory' ? checkboxState.manageInventory :
                                    name === 'Manage Orders' ? checkboxState.manageOrders : checkboxState.all}
                                    onChange={handleCheckboxChange}
                                />
                                </td>
                                <td className="p-4">
                                {name === 'All' ? 
                                <div className="flex items-center">
                                    <Chip color={param === 'UPC' ? "amber" : "teal"} value={param} className="ml-2 text-[0.7rem] w-fit"/>
                                    <Chip color="teal" value="Order Id" className="ml-2 text-[0.7rem] w-fit"/>
                                </div>
                                :
                                <Chip color={param === 'UPC' ? "amber" : "teal"} value={param} className="ml-2 text-[0.7rem] w-fit"/>}
                                
                                </td>
                                <td className="p-4 w-[20rem] text-wrap">
                                    <Typography variant="small" color="blue-gray" className="font-semibold text-[0.75rem]">
                                    {reqResponse}
                                    </Typography>
                                </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="text" color="blue-gray" onClick={handleOpen}>
                        Close
                    </Button>
                    <Button variant="gradient" color="blue" onClick={handleAddBatch}>
                        Submit
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}
