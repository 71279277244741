import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { InformationCircleIcon, UserIcon } from "@heroicons/react/24/solid";

export default function AddUsers({ updateTable }) {
    const [open, setOpen] = useState(false);
    const [userCredentials, setUserCredentials] = useState({ email: '', password: '', role: '', firstName: '', lastName: '' });
    const [formErrors, setFormErrors] = useState({ email: false, password: false, firstName: false, lastName: false });
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Clear errors when modal is closed
        if (!open) {
            setFormErrors({ email: false, password: false, firstName: false, lastName: false });
            setErrorMessage('');
        }
    }, [open]);

    const handleOpen = () => setOpen(!open);

    const toggleUpdateTable = () => {
        updateTable(prevState => !prevState);
    };

    const handleAddUser = () => {
        let hasError = false;

        // Check for errors
        const newFormErrors = {
            email: !userCredentials.email,
            password: !userCredentials.password,
            role: !userCredentials.role,
            firstName: !userCredentials.firstName,
            lastName: !userCredentials.lastName,
        };

        // Update formErrors state
        setFormErrors(newFormErrors);

        // Check if any input field is empty
        for (const error in newFormErrors) {
            if (newFormErrors[error]) {
                hasError = true;
            }
        }

        if (hasError) {
            return;
        }

        axios.post('/api/users/add-user', userCredentials)
            .then(response => {
                if (response.data.message) {
                    console.log('Added user');
                    toast.success(`${response.data.message}`);
                    setUserCredentials({ email: '', password: '', role: '', firstName: '', lastName: '' })
                    setOpen(!open)
                    toggleUpdateTable()
                } else {
                    console.log("New User: ", response.data.user)
                    toast.error(`${response.data.message}`)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || 'An error occurred during add.';
                setErrorMessage(errorMessage);
                toast.error(errorMessage);
            });
    };

    return (
        <>
            <Button onClick={handleOpen} variant="gradient" color="blue">
                Add User
            </Button>
            <Dialog
                className="bg-opacity-80"
                size="xs"
                open={open}
                handler={handleOpen}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}
            >
                <DialogHeader className="flex flex-row gap-2">Add new user <UserIcon className="h-5 w-5" /></DialogHeader>
                {errorMessage && <div className="text-red-500 text-sm font-semibold flex flex-row items-center mt-2 ml-4"> 
                    <InformationCircleIcon className="h-4 w-4"/>{errorMessage}
                </div>}
                {Object.entries(formErrors).map(([field, error]) => (
                    error && (
                        <div key={field} className="text-red-500 text-sm font-semibold mt-2 ml-4 flex flex-row gap-1 items-center">
                            <InformationCircleIcon className="h-4 w-4"/>{`${field.charAt(0).toUpperCase() + field.slice(1)} Required!`}
                        </div>
                    )
                ))}

                <DialogBody>
                    <div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium text-black">Email</label>
                            <input
                                id="email"
                                className={`mt-1 p-2 w-full bg-gray-50 bg-opacity-50 border border-gray-600 rounded-md text-gray-600 ${formErrors.email && 'border-red-500'}`}
                                type="email"
                                onChange={e => setUserCredentials({ ...userCredentials, email: e.target.value })}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="firstName" className="block text-sm font-medium text-black">First Name</label>
                            <input
                                id="firstName"
                                className={`mt-1 p-2 w-full bg-gray-50 bg-opacity-50 border border-gray-600 rounded-md text-gray-600 ${formErrors.firstName && 'border-red-500'}`}
                                type="text"
                                onChange={e => setUserCredentials({ ...userCredentials, firstName: e.target.value })}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="lastName" className="block text-sm font-medium text-black">Last Name</label>
                            <input
                                id="lastName"
                                className={`mt-1 p-2 w-full bg-gray-50 bg-opacity-50 border border-gray-600 rounded-md text-gray-600 ${formErrors.lastName && 'border-red-500'}`}
                                type="text"
                                onChange={e => setUserCredentials({ ...userCredentials, lastName: e.target.value })}
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-black">Role</label>
                            <select
                                id="role"
                                className="bg-gray-50 p-1 h-12 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={e => setUserCredentials({ ...userCredentials, role: e.target.value })} // Update role when user selects a role
                                required
                            >
                                <option disabled selected>Select Role</option>
                                <option value="User">User</option>
                                <option value="Admin">Admin</option>
                            </select>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-black">Password</label>
                            <input
                                id="password"
                                className={`mt-1 p-2 w-full bg-gray-50 bg-opacity-50 border border-gray-600 rounded-md text-gray-600 ${formErrors.password && 'border-red-500'}`}
                                name="password"
                                type="text"
                                onChange={e => setUserCredentials({ ...userCredentials, password: e.target.value })}
                                required
                            />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleAddUser}>
                        <span>Confirm</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}
