import {
    S3Client,
    PutObjectCommand
} from "@aws-sdk/client-s3";

const getLocal = () => {
    let localDataInit = localStorage.getItem('user');
    return (JSON.parse(localDataInit));
}

export async function uploadFileToS3(s3Folder, file, fileName) {    
    const s3Client = new S3Client({
        region: process.env.REACT_APP_S3_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
        }
    })

    console.log('s3Client', process.env.REACT_APP_S3_REGION);

    const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `${s3Folder}/${fileName}.${file.name}`,
        Body: file,
        ContentType: file.type 
    }

    try {
        const data = await s3Client.send(new PutObjectCommand(params));
        console.log('File uploaded successfully:', data);
        return data;
    } catch (err) {
        console.error('Error uploading file:', err);
        throw err;
    }
}
