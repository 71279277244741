import axios from 'axios'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

const fetchReturnReason = async (returnReasonCode) => {
    try {
        const response = await axios.get(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/localize/code/${returnReasonCode}`)
        return response.data.value || 'no return code found'
    } catch (error) {
        console.error("Error fetching return reason:", error)
        return "Return reason code not found"
    }
}

const exportData = async (batchId, category) => {
    try {
        const [NexResponse, ACResponse] = await Promise.all([
            axios.get(`/api/refund-audits/get/${batchId}`),
            axios.get(`https://api-central.outdoorequippedservice.com/api/v1/warehouse-refunds/audits/batch-content/${batchId}`)
        ])

        const timeStamp = new Date()
        const formattedDate = `${timeStamp.getMonth() + 1}-${timeStamp.getDate()}-${timeStamp.getFullYear()}`

        const category1 = { header: ["UPC", "SKU 1", "SKU 2"], data: [], sheetName: "Manage Inventory" }
        const category2 = { header: ["Order Id", "Tracking Id", "Fulfillment", "SKU", "Seller Notes", "Item Total"], data: [], sheetName: "Manage Orders" }
        const category3 = {
            header: [
                "Order Id", "RMA Id", "SKU", "Outbound Shipping", "Return Reason", "Buyer Comments", "Gift Badge",
                "Replacement Badge", "Amazon Refunded", "Manually Refunded", "Refund Total Amount"
            ],
            data: [],
            sheetName: "Manage Returns"
        }

        const categories =
            category.includes('manageInventory') && category.includes('manageOrders') ? [category1, category2]
            : category.includes('manageInventory') && category.includes('manageReturns') ? [category1, category3]
            : category.includes('manageOrders') && category.includes('manageReturns') ? [category3]
            : category.includes('manageInventory') ? [category1]
            : category.includes('manageOrders') ? [category2]
            : category.includes('manageReturns') ? [category3]
            : [category1, category2, category3]

        const acContent = ACResponse.data[0].batchDetails

        console.log("ac content: ", acContent)
        console.log("categories: ", categories)

        const fetchReturnReasonPromises = []

        NexResponse.data.result.batchContent.forEach(batch => {
            const acItem = acContent.find(item => item._id === batch._id)
            
            categories.map(category => {
                if (category?.sheetName === "Manage Inventory") {
                    category.data.push({
                        "UPC": batch?.upc || '',
                        "SKU 1": acItem?.manageInventoryData?.[0]?.sku || '',
                        "SKU 2": acItem?.manageInventoryData?.[1]?.sku || ''
                    })
                }
                
                if (acItem?.manageOrdersData && category?.sheetName === "Manage Orders") {
                    if (acItem?.manageOrdersData.length > 0) {
                        acItem.manageOrdersData.forEach(order => {
                            
                            if (Array.isArray(order.PackageItems)) {
                                order.PackageItems.forEach(item => {
                                    order.OrderItems.forEach(cost => {
                                        const packageItemSKU = item?.SellerSKU;
                                        const packageOrderId = item?.OrderItemId;
                
                                        const orderItemSKU = cost?.SellerSKU;
                                        const orderItemsId = cost?.OrderItemId;
                
                                        const costChecker = (packageItemSKU === orderItemSKU) && (packageOrderId === orderItemsId);
                                        const itemTotal = costChecker ? cost?.ItemCost?.Total?.Amount : 'not matched';
                
                                        category.data.push({
                                            "Order Id": batch?.orderId || '',
                                            "Tracking Id": order?.TrackingId || '',
                                            "Fulfillment": order?.Fulfillment || '',
                                            "SKU": item?.SellerSKU || '',
                                            "Seller Notes": order?.SellerNotes || '',
                                            "Item Total": itemTotal
                                        });
                                    });
                                });
                            } else {
                                category.data.push({
                                    "Order Id": batch?.orderId || 'there',
                                    "Tracking Id": order?.TrackingId || '',
                                    "Fulfillment": order?.Fulfillment || '',
                                    "SKU": '',
                                    "Seller Notes": order?.SellerNotes || '',
                                    "Item Total": 'not matched'
                                });
                            }
                        });
                    } else {
                        category.data.push({
                            "Order Id": batch?.orderId || 'here'
                        });
                    }
                }
            
                if (acItem?.manageReturnsData && category?.sheetName === "Manage Returns") {
                    if (acItem.manageReturnsData.length > 0) {
                        acItem.manageReturnsData.forEach(returns => {
                            const returnReasonCode = returns?.returnRequestItems[0]?.returnReasonStringId
                            const repResolution = returns?.returnRequestItems[0]?.resolution
                            const repOrderId = returns?.returnRequestItems[0]?.replacementOrderId
                            const repBadgeVal = repResolution === 'Replacement' && repOrderId ? 'Yes' : 'No'
    
                            const fetchPromise = fetchReturnReason(returnReasonCode).then(returnReasonValue => {
                                category.data.push({
                                    "Order Id": batch?.orderId,
                                    "RMA Id": returns?.rmaId,
                                    "SKU": returns?.returnRequestItems[0]?.merchantSKU,
                                    "Outbound Shipping": returns?.outboundShipping === false ? "No" : "Yes",
                                    "Return Reason": returnReasonValue,
                                    "Buyer Comments": returns?.returnRequestItems[0]?.customerComments,
                                    "Gift Badge": returns?.giftReturn === false ? "No" : "Yes",
                                    "Replacement Badge": repBadgeVal,
                                    "Amazon Refunded": returns?.amazonRefunded ? 'Yes' : 'No',
                                    "Manually Refunded": returns?.manuallyRefunded ? 'Yes' : 'No',
                                    "Refund Total Amount": returns?.refundTotal?.totalAppliedRefund?.currencyValue || ''
                                })
                            })
                            fetchReturnReasonPromises.push(fetchPromise)
                        })
                    } else {
                        category.data.push({
                            "Order Id": batch?.orderId,
                        })
                    }
                }
            });
        })

        await Promise.all(fetchReturnReasonPromises)

        if (categories[categories.length - 1]?.sheetName === "Manage Returns") {
            const sortedManageReturnsData = []
            NexResponse.data.result.batchContent.forEach(batch => {
                const batchOrderId = batch?.orderId
                const sortedData = categories[categories.length - 1].data.filter(item => item['Order Id'] === batchOrderId)
                sortedManageReturnsData.push(...sortedData)
            })
            categories[categories.length - 1].data = sortedManageReturnsData
        }

        const wb = XLSX.utils.book_new()
        categories.forEach(({ header, data, sheetName }) => {
            const ws = XLSX.utils.json_to_sheet(data, { header })
            XLSX.utils.book_append_sheet(wb, ws, sheetName)
        })

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([wbout], { type: 'application/octet-stream' })
        saveAs(blob, `wr-${batchId}-${formattedDate}.xlsx`)
    } catch (error) {
        console.error("Error exporting data:", error)
    }
}

export default exportData
