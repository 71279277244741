import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

import '../src/index.css';
import Home from './Views/MainPages/Home';
import LoginPage from './Views/MainPages/LoginPage';
import AdminPage from './Views/Admin/_AdminPage';
import ManageDepartmentsView from "./Views/Admin/ManageDepartmentsView";
import ManageUsersView from "./Views/Admin/ManageUsersView";
import AmazonCases from "./Views/DepartmentViews/ListingsTools/_Tool-AmazonCases";
import WebScrapers from "./Views/DepartmentViews/ListingsTools/_Tool-WebScrapers";
import ListingsToolsView from "./Views/DepartmentViews/ListingsTools/_ListingsToolsView";
//import { useUser } from "./store/recoilStates";
import Errorpage from "./Views/MainPages/Errorpage";
import ResetPW from "./Views/MainPages/ResetPw";
import WarehouseToolsView from "./Views/DepartmentViews/Warehouse/_WarehouseTools";
import WarehouseRefunds from "./Views/DepartmentViews/Warehouse/_Tool-WarehouseRefunds";
import PricingToolsView from "./Views/DepartmentViews/Pricing/_PricingTools";
import CouponPromotions from "./Views/DepartmentViews/Pricing/_Tool-CouponPromotions";
import ProfSettings from "./Views/MainPages/ProfSettings";
import RefProcessView from "./Views/DepartmentViews/Warehouse/Refund/RefProcessView";
import DataGathering from "./Views/DepartmentViews/Pricing/DataGathering/Tool-DataGathering";

export default function App() {
  // const { user } = useUser();

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginPage/>}/>
          <Route exact path="/login" element={<LoginPage/>}/>
          <Route exact path="/reset-password" element={<ResetPW/>}/>

          <Route exact path='/' element={ <PrivateRoute /> }>
            <Route exact path="/home" element={<Home/>}/>
            <Route exact path='*' element={<Errorpage/>}/>
            <Route exact path='/profile-settings' element={<ProfSettings/>}/>

            {/* Admin Routes */}
            {/* {user.role === 'Admin' && (
              <> */}
                <Route exact path='/adminNXS0011/dashboard' element={<AdminPage/>}/>
                <Route exact path='/adminNXS0011/manage-departments' element={<ManageDepartmentsView/>}/>
                <Route exact path='/adminNXS0011/manage-users' element={<ManageUsersView/>}/>
              {/* </>
            )} */}

            {/* Listings Tool */}
            <Route exact path='/listings-tools' element={<ListingsToolsView/>}/>
            <Route exact path='/listings-tool/amazon_cases' element={<AmazonCases/>}/>
            <Route exact path='/listings-tool/web_scrapers' element={<WebScrapers/>}/>
            {/* Warehouse Tool */}
            <Route exact path='/warehouse-tools' element={<WarehouseToolsView/>}/>
            <Route exact path='/warehouse-tools/warehouse-refunds' element={<WarehouseRefunds/>}/>
            <Route exact path='/warehouse-tools/warehouse-refunds/refund-actions/:id' element={<RefProcessView/>}/>
            {/* Pricing Tool */}
            <Route exact path='/pricing-tools' element={<PricingToolsView/>}/>
            <Route exact path='/pricing-tools/coupon-promotions' element={<CouponPromotions/>}/>
            <Route exact path='/pricing-tools/data-gathering' element={<DataGathering/>}/>
          </Route>
        </Routes> 
      </Router>
    </>
  );
}

const isAuthenticated = () => {
  const token = localStorage.getItem('jwt');
  try {
    jwtDecode(token);
    return true;
  } catch (error) {
    return false;
  }
}

const PrivateRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />;
}
