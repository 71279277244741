import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useRecoilValue } from 'recoil'
import { userActivityStateWH, userActivityStatePrc, userActivityStateListings } from '../store/userActivityState'

import { Link } from 'react-router-dom'
import { useUser } from '../store/userState'
import { PaperAirplaneIcon, WrenchIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid'
import { Avatar, Tooltip } from '@material-tailwind/react'

const DepartmentCards = ({department, deptTools, index}) => {

    const [userDept, setUserDept]= useState([])
    const [isLoad, setIsLoad] = useState(false)

    const warehouseActivity = useRecoilValue(userActivityStateWH)
    const pricingActivity = useRecoilValue(userActivityStatePrc)
    const listingsActivity = useRecoilValue(userActivityStateListings)

    const departmentActivity = department === 'Warehouse' ? warehouseActivity :
                            department === 'Pricing' ? pricingActivity :
                            department === 'Listings Executive' ? listingsActivity : 
                            '....'

    const { user } = useUser()
    const departmentPermission = user.permissions[0].department

    let permission
    if (departmentPermission === 'Admin Access') {
        permission = false
    } else if (department !== departmentPermission) {
        permission = true
    } else {
        permission = null
    }

    useEffect(() => {
        setIsLoad(true)
        axios.get('/api/users/get-users?role=&isActive=')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get users")
                } else {
                    setUserDept(response.data.result) 
                }
                setIsLoad(false)
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [])

    const maxAvatars = 9;
    
    return (
        <aside className="bg-white bg-opacity-15 relative shadow-xl backdrop-filter backdrop-blur-sm text-white p-6 rounded-lg w-full max-w-lg font-mono">
            <div className="flex justify-between items-center">
            <div className="flex space-x-2 text-red-500">
                <div className="w-3 h-3 rounded-full bg-[#44BCFF]"></div>
                <div className="w-3 h-3 rounded-full bg-[#FF44EC]"></div>
                <div className="w-3 h-3 rounded-full bg-[#FF675E]"></div>
            </div>
            <p className="text-xl font-semibold">{department}</p>
            </div>
            <p className="text-[#44BCFF] text-xl my-3 flex items-center"><WrenchScrewdriverIcon className='h-5 w-5 mr-1'/> Tools</p>
            <div className="mt-4 flex">

                {deptTools[index].map(({ toolName }) => (
                        <p key={toolName} className="text-white bg-blue-500 p-1 rounded-lg mx-2 flex items-center"><WrenchIcon className='h-4 w-4 mr-1'/> {toolName}</p>
                    ))}
            </div>
            <div className="flex justify-between items-center mt-5">
                <div className='flex flex-row items-center gap-2'>
                    <div className="flex items-center -space-x-4">
                    {userDept.length <= 0 ?
                        <>
                            {[...Array(5)].map((_, index) => (
                                <Avatar
                                key={index}
                                size='sm'
                                variant="circular"
                                alt="Avatar"
                                className="border-2 animate-pulse border-white border-opacity-20 hover:z-10 focus:z-10"
                                src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                            />
                            ))}
                        </> 
                        :
                        (userDept.filter(user => user.permissions[0].department === department).slice(0, maxAvatars).map(({ profileImage, firstName, lastName }, index) => (
                            <Tooltip content={`${firstName} ${lastName}`}>
                                <Avatar
                                    key={index}
                                    size='sm'
                                    variant="circular"
                                    alt={firstName}
                                    className="border-2 border-white border-opacity-20 hover:z-10 focus:z-10"
                                    src={profileImage ? `https://nexus-portal.s3.amazonaws.com/profileImages/${profileImage}` : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"}
                                />
                            </Tooltip>
                            )))
                        }

                        </div>
                        {userDept.filter(user => user.permissions[0].department === department).length > maxAvatars && (
                            <div className="text-sm text-gray-500 z-10">
                                +{userDept.filter(user => user.permissions[0].department === department).length - maxAvatars} more
                            </div>
                        )}
                </div>
                <Link to={department === 'Listings Executive' ? '/listings-tools' : 
                    department === 'Warehouse' ? '/warehouse-tools' :
                    department === 'Pricing' ? '/pricing-tools'
                    : '/workspace-empty'} >
                    <button className={`bg-black bg-opacity-70 hover:bg-opacity-100 rounded-lg p-2 flex items-center 
                    ${permission ? 'cursor-not-allowed text-red-500' : ''}`} 
                    disabled={permission}>
                        <PaperAirplaneIcon className='h-5 w-5 mr-1 animate-pulse'/>{permission ? 'No access' : 'Access Workspace'}
                    </button>
                </Link>
            </div>
            <div className='mt-1 flex flex-row gap-1'>
                <p className=''>Last Activity</p> <p className='text-gray-400'>{departmentActivity}</p>
            </div>

        </aside>
    )
}

export default DepartmentCards


