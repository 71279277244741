import React,{  useEffect, useState } from 'react'
import axios from 'axios'
import { useRecoilValue } from 'recoil'
import { userActivityStatePrc } from '../../../store/userActivityState'

import { toast } from 'react-toastify'
import { ArrowDownTrayIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Card, Spinner, Tooltip, Typography } from '@material-tailwind/react'

import Pagination from "../../../Components/Pagination"
import SearchInput from "../../../Components/SearchInput"
import { TicketIcon } from '@heroicons/react/24/solid'
import exportCouponXlsx from '../../../middlewares/Pricing/couponPromotionsExportXls'

const TABLE_HEAD = ["Request ID", "Coupon Name", "Request Date", "Requested by", "Status", "Actions"]

const CouponsTable = ({isAltered}) => {

    const [coupReqList, setCoupReqList] = useState([])
    const [coupAcReqList, setCoupAcReqList] = useState([]) 
    const [isChanged, setIsChanged] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [isAltereds, setIsAltereds] = useState()
    const [downloadingBatch, setDownloadingBatch] = useState({})

    const lastActivity = useRecoilValue(userActivityStatePrc)

    const requestsPerPage = 10
    const totalPages = Math.ceil(coupReqList.length / requestsPerPage)
    const indexOfLastRequest = currentPage * requestsPerPage
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage

    const filteredRequests = coupReqList.filter(item =>
        (item.couponId && item.couponId.toString().includes(searchTerm.trim())) ||
        (item.batchId && item.batchId.toString().includes(searchTerm.trim())) ||
        (item.addedBy && item.addedBy.toLowerCase().includes(searchTerm.toLowerCase())) 
    )
    filteredRequests.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    const requestData = filteredRequests.slice(indexOfFirstRequest, indexOfLastRequest)

    useEffect(() => {
        const toggleInterval = setInterval(() => {
            setIsAltereds(prevState => !prevState)
        }, 2000) 
        return () => {
            clearInterval(toggleInterval)
        }
    }, [])

    useEffect(() => {
        axios.get('/api/coupon-promotions/all-batches')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get batches")
                } else {
                    setCoupReqList(response.data.result)

                    axios.get(`https://api-central.outdoorequippedservice.com/api/v1/coupon-promotions/promotions/all-batch`)
                    .then(res => setCoupAcReqList(res.data))
                } 
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }, [isAltered, isAltereds, isChanged])

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1)
    }

    const handleExportData = async (batchId) => {
        setDownloadingBatch(prevState => ({ ...prevState, [batchId]: true }))
        await exportCouponXlsx(batchId)
        setDownloadingBatch(prevState => ({ ...prevState, [batchId]: false }))
    }

    const handleDeleteReq = (requestId) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete this request: ${requestId}?`)

        if (isConfirmed) {
            axios.delete(`/api/coupon-promotions/delete/${requestId}`)
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`)
                        setIsChanged(prevState => !prevState)
                        axios.delete(`https://api-central.outdoorequippedservice.com/api/v1/coupon-promotions/promotions/batch/${requestId}`)
                        .then(response => {console.log(response.data.result)})

                    } else {
                        toast.error(`${response.data.message}`)
                        console.log('Error:', response.data.error)
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                })
        }
    }

    const handleReopenBatch = (batchId) => {

        const updatedStatus = {
            status: {
                name: 'open',
                message: 'OK!'
            }
        }
        axios.put(`/api/coupon-promotions/update/status/${batchId}`, updatedStatus)
            .then(response => {
                if(response.data.message) {
                    setIsChanged(prevState => !prevState)
                    console.log('repoening', batchId)
                    axios.delete(`https://api-central.outdoorequippedservice.com/api/v1/coupon-promotions/promotions/batch/${batchId}`)
                    .then(response => {console.log(response.data.result)})
                }else {
                    toast.error(`${response.data.message}`)
                    console.log('Error:', response.data.error)
                }
            })
    }

    return (
        <div className="flex flex-col w-full h-full">
            <div className='flex items-center justify-items-start text-white'>
            <SearchInput  value={searchTerm} onChange={handleSearch}/>
                <div className='flex items-center ml-4'>
                    <p className="mr-5">Total Requests: <strong className='text-blue-400'>{coupReqList.length}</strong></p>
                    <p className="font-semibold mr-5">Last activity <span className="text-gray-500">{lastActivity === 'NaN min ago' ? '0 min ago' : lastActivity}</span></p>
                </div>
            </div>

            <Card className="min-h-fit max-h-[37rem] min-w-full max-w-[60rem] bg-opacity-70 bg-[#101927] rounded-b-none overflow-y-auto">
                <table className="w-full min-w-max table-auto text-center ">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-b border-[#374050] bg-[#1E2936] bg-opacity-90 p-4 sticky top-0 z-10">
                                        <Typography
                                            variant="small"
                                            color="white"
                                            className="font-bold leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {coupReqList.length <= 0 ?
                            <>
                                {[...Array(9)].map((_, index) => (
                                    <tr key={index} className='border-y bg-blue-gray-600 border-[#374050] animate-pulse'>
                                        <td colSpan="12" className="p-4">
                                            <div className="h-[2rem] rounded"></div>
                                        </td>
                                    </tr>
                                ))}
                            </> :
                                (requestData.map(({ batchId, couponId, addedBy, updatedAt, status }) => {
                                    const date = new Date(updatedAt)
                                    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
                                    
                                    const options = { month: 'long', day: 'numeric', year: 'numeric' }
                                    const formattedDate = date.toLocaleDateString('en-US', options)
                                    const formattedDateTime = `${formattedDate} ${formattedTime}`

                                    const item = coupAcReqList.find(item => item.batchId === batchId)
                                    const couponTitle = item?.promotionDetails?.[0]?.title

                                return(
                                    <tr key={batchId} className="border-y border-[#374050] hover:bg-blue-900">
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {batchId}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal flex items-center justify-center">
                                            {couponTitle ? 
                                                <>
                                                    <Tooltip content={`Coupon ID: ${couponId}`}>
                                                        <TicketIcon className='h-6 w-6 mr-1 text-teal-300'/>
                                                    </Tooltip>
                                                    {couponTitle}
                                                </>
                                                :  <div className='flex items-center justify-center'>
                                                <Tooltip content={couponId}>
                                                        <TicketIcon className='h-6 w-6 mr-1 text-teal-300'/>
                                                    </Tooltip>
                                                    <p className='text-3xl font-bold animate-pulse'>...........</p>
                                                
                                                </div>
                                            }
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {formattedDateTime}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-normal">
                                                {addedBy}
                                            </Typography>
                                        </td>
                                        <td className="p-4">
                                            <Typography variant="small" color="white" className="font-bold">
                                            {status.name === 'open' ? 
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Open <ExclamationCircleIcon className='text-blue-500 ml-2 h-6 w-6 stroke-blue-900'/>
                                                </div>
                                                : status.name === 'completed' ? 
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Completed <CheckCircleIcon className='text-green-500 ml-2 mr-1 h-6 w-6 stroke-green-700'/>
                                                </div>
                                                : status.name === 'in-progress' ?                            
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Processing <ArrowPathIcon className='text-orange-400 animate-spin mx-2 h-6 w-6 stroke-orange-400'/> 
                                                </div>                                  
                                                : 
                                                <Tooltip content={status.message}>
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Error <ExclamationTriangleIcon className='text-red-500 animate-pulse ml-2 h-6 w-6'/>
                                                </div>
                                                </Tooltip>

                                            }
                                            </Typography>
                                        
                                        </td>
                                        <td className="p-4 text-white">
                                            {status.name === 'completed' ?
                                                <Tooltip content="Export file">
                                                    <button onClick={() => handleExportData(batchId)} className="bg-blue-500 rounded-lg p-1 m-1 px-2">
                                                        {downloadingBatch[batchId] ? <Spinner/> : <ArrowDownTrayIcon className="h-5 w-5" />}
                                                    </button>                      
                                                </Tooltip>
                                            :
                                            ''
                                            }
                                        {status.name !== 'open' ? (
                                        <Tooltip content="Resync coupon">
                                            <button className="bg-orange-500 rounded-lg p-1 m-1 px-2" 
                                            onClick={() => handleReopenBatch(batchId)}
                                            >
                                                <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                                            </button>                      
                                        </Tooltip>                  
                                    ) : ""}
                                        <Tooltip content="Delete Request">
                                            <button className="bg-red-500 rounded-lg p-1 m-1 px-2">
                                                <TrashIcon className="h-5 w-5" onClick={() => handleDeleteReq(batchId)}/>
                                            </button>                      
                                        </Tooltip>
                                        </td>
                                    </tr>
                                )}))         
                            }
                    </tbody>
                </table>
            </Card>
            <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    currentPage={currentPage}
                    customClass={`bg-opacity-50`}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                />
            </div>
        </div>
    )
}

export default CouponsTable
