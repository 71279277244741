import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import {Button ,Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from "@material-tailwind/react";
import { CheckCircleIcon, ExclamationTriangleIcon, TrashIcon, WrenchIcon, WrenchScrewdriverIcon, XCircleIcon } from "@heroicons/react/24/solid";

export default function ToolsManagement({ departmentTools, toolIds, departmentId }) {
    const [open, setOpen] = useState(false);
    const [addTool, setAddTool] = useState({ toolName: '' });
    const [addToolOpen, setAddToolOpen] = useState(false);

    const handleOpen = () => {
    console.log("tools id:", toolIds)
    console.log("department id:", departmentId)
    setOpen(!open)
    }

    const handleAddToolOpen = () => {
        setAddToolOpen(!addToolOpen);
        console.log("addToolOpen:", addToolOpen)
    }

    const handleAddTool = () => {

        axios.post(`/api/departments/${departmentId}/tools`, addTool)
        .then(response => {
            if (response.data.message) {
                console.log('Added: ', addTool.toolName);
                toast.success(`${response.data.message}`);
                setAddToolOpen(!addToolOpen)
                setOpen(!open)
                window.location.reload()
            } else {
                console.log("New dept: ", response.data.result);
                toast.error(`${response.data.message}`);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred during add.';
            toast.error(errorMessage);
        })
    };

    const handleDeleteDept = (index) => {

        const selectedTool = departmentTools[index].toolName
        const toolToDelete = toolIds[index];
        const isConfirmed = window.confirm(`Are you sure you want to delete '${selectedTool}' department?`);
        
        if (isConfirmed) {
            axios.delete(`/api/departments/${departmentId}/tools/${toolToDelete}`)
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`);
                        console.log(`Deleted tool ${selectedTool}`)
                        window.location.reload()
                    } else {
                        toast.error(`${response.data.message}`);
                        console.log('Error:', response.data.error);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    return (
    <>
        <button className="p-1 text-blue-500 w-fit h-fit hover:scale-125 hover:text-blue-700 transition" onClick={handleOpen}>
        <WrenchScrewdriverIcon className="h-6 w-6"/></button>
        <Dialog open={open} handler={handleOpen}>
        <DialogHeader>
            <Typography variant="h5" color="blue-gray">
                Tools Management
            </Typography>
        </DialogHeader>
        <DialogBody divider className="flex flex-col gap-3">
            <div className="flex flex-row gap-2 p-8 justify-around h-fit mb-5 border-b-2">
                {addToolOpen ?
                    <div className="flex flex-row gap-2 items-center">
                    <div class="flex items-center justify-center w-[10rem] mr-6">
                    <div className="relative">
                        <input
                        id="tool"
                        name="tool"
                        type="text"
                        onChange={e => setAddTool({addTool, toolName: e.target.value})}
                        class="border-b border-gray-300 py-1 focus:border-b-2 focus:border-blue-700 transition-colors focus:outline-none peer bg-inherit"
                        />
                        <label for="username" class="absolute left-0 top-1 cursor-text peer-focus:text-xs peer-focus:-top-4 transition-all peer-focus:text-blue-700">New Tool</label>
                    </div>
                    </div>
                        <button className="text-red-500" onClick={handleAddToolOpen}><XCircleIcon className="w-7 h-7"/></button>
                        <button className="text-green-500" onClick={handleAddTool}><CheckCircleIcon className="w-7 h-7"/></button>
                    </div>
                    :
                    <Button  variant="gradient" color="blue" className="w-fit p-3 mb-6" onClick={handleAddToolOpen}>
                        Add Tool
                    </Button>
                }
            </div>

            {departmentTools.map(({ toolName }, index) => (
            <div className="flex flex-row items-center justify-between">
                <Typography key={index} color="blue" variant="h5" className="flex flex-row items-center gap-1">
                    <WrenchIcon className="h-6 w-6"/>{toolName}
                </Typography>
                <button className="p-1 bg-red-500 m-1 rounded-md text-white flex items-center flex-row gap-1">
                    <ExclamationTriangleIcon className="h-5 w-5" color="yellow"/>Maintenance Mode
                </button>
                <div className="flex flex-row gap-1">
                    <button className="p-1 bg-orange-500 m-1 rounded-md text-white">Disable</button>
                    <button onClick={() => handleDeleteDept(index)} className="p-1 bg-red-500 m-1 rounded-md text-white"><TrashIcon className="w-6 h-6 hover:scale-125 transition"/></button>
                </div>
            </div>

            ))}

        </DialogBody>
        <DialogFooter className="space-x-2">
            <Button variant="text" color="red" onClick={handleOpen}>
            Cancel
            </Button>
            <Button variant="gradient" color="green" onClick={handleOpen}>
            Save
            </Button>
        </DialogFooter>
        </Dialog>

    </>
    );
}
