import React, { useState, useEffect} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Card, Spinner, Tooltip, Typography } from "@material-tailwind/react"
import { ArrowDownTrayIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/solid'
import Pagination from '../../../../Components/Pagination'

const TABLE_HEAD = ["Request ID", "Requested By", "Source File", "Brand Filters", "Status", "Actions"]

export function DataTable({ isAltereds }) {

    const [requestData, setRequestData] = useState([])
    const [isAltered, setIsAltered] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const requestsPerPage = 10
    const totalPages = Math.ceil(requestData.length / requestsPerPage)
    const indexOfLastRequest = currentPage * requestsPerPage
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage

    requestData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    const sortedData = requestData.slice(indexOfFirstRequest, indexOfLastRequest)

    useEffect(() => {
        axios.get('/api/data-requests/all')
        .then(response => {
            if (response.data.message) {
                console.log("Cannot get batches")
            } else {
                setRequestData(response.data.requests)
                console.log(response.data.requests)
            }
        })
        .catch(error => {
            console.error('Error:', error)
        })
    }, [isAltereds, isAltered])

    const handleDeleteRequest = (requestId) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this request?')

        if(isConfirmed){
            axios.delete(`/api/data-requests/delete/${requestId}`)
            .then(response =>{
                if(response.data.message){
                    toast.success(response.data.message)
                    setIsAltered(prevstate => !prevstate)
                }
                else{
                    toast.error(response.data.message)
                }
            })
            .catch(error =>{
                console.error('Error',error)
            })
        }

    }

    return (
        <div className="flex flex-col w-full h-full">
            <Card className="min-h-fit max-h-[37rem]  min-w-full max-w-[90.4rem] bg-[#101927] bg-opacity-70 rounded-b-none overflow-y-auto">
                <table className="w-full min-w-max table-auto text-center">
                <thead>
                    <tr>
                    {TABLE_HEAD.map((head) => (
                        <th
                        key={head}
                        className="border-b border-[#374050] bg-[#1E2936] bg-opacity-90 p-4 sticky top-0 z-10"
                        >
                        <Typography
                            variant="small"
                            color="white"
                            className="font-normal leading-none opacity-70"
                        >
                            {head}
                        </Typography>
                        </th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    {requestData.length <= 0 ? 
                    <>
                        {[...Array(10)].map((_, index) => (
                            <tr key={index} className='border-y bg-blue-gray-600 border-[#374050] animate-pulse'>
                                <td colSpan="12" className="p-4">
                                    <div className="h-[2rem] rounded"></div>
                                </td>
                            </tr>
                        ))}
                    </> 
                    :   
                    (sortedData.map((item) => {
                        const displayBrands = item.brandFilters.slice(0, 3);
                        const remainingBrands = item.brandFilters.slice(3);

                        return (
                            <tr key={item.requestId} className='border-y border-[#374050] hover:bg-blue-900'>
                            <td className='p-4'>
                                <Typography
                                variant="small"
                                color="white"
                                className="font-normal"
                                >
                                {item.requestId}
                                </Typography>
                            </td>
                            <td className='p-4'>
                                <Typography
                                variant="small"
                                color="white"
                                className="font-normal"
                                >
                                {item.requestedBy}
                                </Typography>
                            </td>
                            <td className='p-4'>
                                <Typography
                                variant="small"
                                color="white"
                                className="font-normal"
                                >
                                {item.dataSource === 'allListings' ? 'All Listings' : 'App Eagle'}
                                </Typography>
                            </td>
                            <td className='p-4'>
                            <Typography
                                variant="small"
                                color="white"
                                className="font-semibold"
                            >
                                {displayBrands.map((filter, index) => (
                                    <span className='m-2 bg-lime-500 text-black p-2 rounded-md' key={index}>{filter.brandName}</span>
                                ))}
                                {remainingBrands.length > 0 && (
                                    <Tooltip content={remainingBrands.map((filter, index) => (
                                        <div key={index}>
                                            {filter.brandName}
                                        </div>
                                    ))}>
                                        <span className='m-2 cursor-pointer'>{`+ ${remainingBrands.length} more`}</span>
                                    </Tooltip>
                                )}
                            </Typography>
                            </td>
                            <td className="p-4">
                                            <Typography variant="small" color="white" className="font-bold">
                                            {item.status.name === 'open' ? 
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Open <ExclamationCircleIcon className='text-blue-500 ml-2 h-6 w-6 stroke-blue-900'/>
                                                </div>
                                                : item.status.name === 'completed' ? 
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Completed <CheckCircleIcon className='text-green-500 ml-2 mr-1 h-6 w-6 stroke-green-700'/>
                                                </div>
                                                : item.status.name === 'in-progress' ?                            
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Processing <ArrowPathIcon className='text-orange-400 animate-spin mx-2 h-6 w-6 stroke-orange-400'/> 
                                                </div>                                  
                                                : 
                                                <Tooltip content={item.status.message}>
                                                <div className='flex w-[10rem] justify-center items-center'>
                                                    Error <ExclamationTriangleIcon className='text-red-500 animate-pulse ml-2 h-6 w-6'/>
                                                </div>
                                                </Tooltip>
                                            }
                                            </Typography>
                                        
                                        </td>
                            <td className="p-4 text-white">
                                {item.status.name === 'completed' ?
                                    <Tooltip content="Export file">
                                        <button className="bg-blue-500 rounded-lg p-1 m-1 px-2">
                                            {/* {downloadingBatch[item.requestId] ? <Spinner/> : <ArrowDownTrayIcon className="h-5 w-5" />} */}
                                        </button>                      
                                    </Tooltip>
                                :
                                ''
                                }
                                {item.status.name !== 'open' ? (
                                <Tooltip content="Resync">
                                    <button className="bg-orange-500 rounded-lg p-1 m-1 px-2" 
                                    >
                                        <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                                    </button>                      
                                </Tooltip>                  
                                ) : ""}
                                <Tooltip content="Delete Request">
                                    <button className="bg-red-500 rounded-lg p-1 m-1 px-2">
                                        <TrashIcon className="h-5 w-5" 
                                            onClick={() => handleDeleteRequest(item.requestId)}
                                        />
                                    </button>                      
                                </Tooltip>
                                </td>
                            </tr>
                        )
                        }))    
                    }

                </tbody>
                </table>
            </Card>
            <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                <Pagination
                    currentPage={currentPage}
                    customClass={`bg-opacity-50`}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                />
            </div>
        </div>

    )
}