import React, { useState } from 'react'
import axios from 'axios'
import { useUser } from '../../store/userState';

import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import neural1 from '../../assets/404neural.webp'
import nexus from '../../assets/nexusLogo.png'
import { Spinner } from '@material-tailwind/react';

const LoginPage = () => {

    const navigate = useNavigate()
    const  {updateUser}  = useUser();
    const [userCredentials, setUserCredentials] = useState({email: '', password: ''}) 
    const [isLoad, setIsLoad] = useState(false)

    const handleLogin = () => {
        setIsLoad(true);
        
        axios.post('/api/users/auth/login', userCredentials)
            .then(response => {
                if (response.data.message) {
                    console.log("Login failed!");
                    toast.error(`Login failed! ${response.data.message}`);
                } else {
                    console.log("login: ", response.data)
                    localStorage.setItem('jwt', response.data.token);
                    localStorage.setItem('id', response.data.user.id);
                    updateUser(response.data.user); 
                    console.log('user: ', response.data.user);
                    navigate(response.data.user.role === 'Admin' ? "/adminNXS0011/dashboard" : "/home");
                }
                setIsLoad(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoad(false);
                const errorMessage = error.response?.data?.message || 'An error occurred during login.';
                toast.error(errorMessage);
            });
        };

    return (
        <>
        <ToastContainer />
        <div className='w-screen h-screen  bg-[#111926] flex justify-items-start items-center flex-col gap-1  relative overflow-hidden'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.6)), url(${neural1})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
            
            <div className='bg-cover w-[20rem] mt-[13rem] flex justify-center h-[5rem] p-2  border-b border-[#232B3C] mb-[2rem]'>
            < img src={nexus} className="w-full h-full" alt='NEXUS'/>
            </div>

            <div className="w-[30%] h-[22rem] border-b-2 border-l border-[#22D2EF] overflow-hidden shadow-xl bg-opacity-40 bg-black p-10 rounded-lg before:w-24 before:h-24 before:absolute before:bg-purple-600 before:rounded-full before:-z-10 before:blur-2xl after:w-32 after:h-32 after:absolute after:bg-sky-400 after:rounded-full after:-z-10 after:blur-xl after:top-24 after:-right-12">
                <h2 className="text-2xl font-bold text-white ">Welcome !</h2>
                <p className="text-lg font-bold text-gray-400 mb-6">Sign in to continue</p>

                <div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
                        <input
                        id="email"
                        className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                        type="email"
                        onChange={e => setUserCredentials ({...userCredentials, email: e.target.value})}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-300">Password</label>
                        <input
                        id="password"
                        className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white"
                        name="password"
                        type="password"
                        onChange={e => setUserCredentials ({...userCredentials, password: e.target.value})}
                        />
                    </div>

                    <div className="flex justify-between">
                        <div></div>
                        <button                                      
                        className="bg-[#22D2EF] text-white px-4 py-2 w-fit font-bold rounded-md hover:bg-opacity-80"
                        type="submit"
                        onClick={handleLogin}
                        >
                            {isLoad ? (
                                <div className="flex gap-1 items-center">                     
                                <span className="ml-1">Signing in</span>
                                <Spinner className='h-5 w-5' color='indigo'/>
                                </div>
                            ) : (
                                'Sign in'
                            )}
                        </button>
                    </div>
                </div>
            </div>

        </div>
        </>                                                                                    

    )
}

export default LoginPage                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         