import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { useUser } from '../store/userState'
import { Link, useNavigate } from 'react-router-dom'
import { Card, Avatar, Typography, List, ListItem, ListItemPrefix } from "@material-tailwind/react"
import { Cog6ToothIcon, PowerIcon, ChartBarSquareIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/solid"

export default function Sidebar() {
  const [userImgUrl, setUserImgUrl] = useState()

  const navigate = useNavigate()
  const { user } = useUser()
  const role = user.role
  const profImageLink = `https://nexus-portal.s3.amazonaws.com/profileImages/${userImgUrl}`

  const handleLogout = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('id')
    navigate('/login')
  }

  useEffect(() => {
    axios.get(`/api/users/get-user/${user.id}`)
        .then(response => {
            if (response.data.message) {
                console.log("Cannot get users")
            } else {
                setUserImgUrl(response.data.profileImage)
            }
        })
        .catch(error => {
            console.error('Error:', error)
        })
  })

  return (
    <Card className="h-full w-full max-w-[20rem] p-4 shadow-xl  bg-[#F8F8FF] bg-opacity-0 text-white rounded-none  border-r border-[#232B3C] overflow-hidden ">
      <div className="flex items-center gap-4 mb-2 p-4">
        <Avatar src={ userImgUrl ? profImageLink : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"} 
        alt="avatar"  className="border-4 border-[#22D2EF] w-28 h-28 mscreen:w-20 mscreen:h-20" />
        <div>
          <Typography variant="h6">{user.firstName} {user.lastName}</Typography>
          <Typography variant="small" color="gray" className="font-normal">
            {role}
          </Typography>
        </div>
      </div>

      <List>
        { role === 'Admin' ? 
        <Link to='/adminNXS0011/dashboard'>
        <ListItem className="text-white">
          <ListItemPrefix>
            <ChartBarSquareIcon className="h-5 w-5" />
          </ListItemPrefix>
          Admin Dashboard
        </ListItem>
        </Link>
        : '' }

        <Link to='/home'>
          <ListItem className="text-white">
            <ListItemPrefix>
              <WrenchScrewdriverIcon className="h-5 w-5" />
            </ListItemPrefix>
            Dashboard
          </ListItem>
        </Link>

        <Link to='/profile-settings'>
          <ListItem className="text-white">
            <ListItemPrefix>
              <Cog6ToothIcon className="h-5 w-5" />
            </ListItemPrefix>
            Profile Settings
          </ListItem>
        </Link>

          <ListItem className="text-white" onClick={handleLogout}>
            <ListItemPrefix>
              <PowerIcon className="h-5 w-5" />
            </ListItemPrefix>
            Log Out
          </ListItem>
      </List>
    </Card>
  )
}
