import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from "react-router-dom";

import Sidebar from '../../../Components/Sidebar'
import FloatBar from '../../../Components/FloatBar'
import neural2 from '../../../assets/neural-networks.webp'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import { AddTransacts } from './AddTransacts';
import { TransactionsTable } from './TransactionsTable';
import SampleDownloadButton from './SampleDownloadButton';

const WarehouseRefunds = () => {
  const [isAltered, setIsAltered] = useState(false);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleAlterState = () => {
    setIsAltered(prevstate => !prevstate);
  };

  return (
    <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-hidden'
    style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.8)), url(${neural2})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

      <FloatBar/>
      <div className='flex flex-row gap-2 w-[95%] h-full  '>
      <Sidebar/>
      <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
        <ToastContainer/>
        <div className='w-full h-fit flex justify-between p-4 text-white'>
          <p className=' text-xl flex items-center'><ArrowUturnLeftIcon className=' mr-5 h-6 w-6 cursor-pointer hover:scale-125 transition' onClick={handleGoBack}/>Warehouse Refunds</p>
        </div>

        <div className='w-full h-fit flex flex-row gap-8 text-white pl-4 my-3'>
          <button className='text-md border-b pb-2 text-gray-300 hover:text-[#22D2EF] hover:border-[#22D2EF] z-10'>Transactions Table</button>
          <AddTransacts onAlterState={handleAlterState}/>
        </div>
        <div className='w-full h-full p-2 pl-4 flex  justify-center m-1 overflow-y-auto overflow-x-auto'>
          <TransactionsTable isAltereds={isAltered}/>
        </div>
      </div>
      </div>
    </div>
  )
}

export default WarehouseRefunds