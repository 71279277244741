import axios from "axios"
import React, { useState } from "react"
import { toast } from "react-toastify"
import { useUser } from "../../../../store/userState"

import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react"
import { Cog8ToothIcon, FolderIcon, FunnelIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/solid"

export function FilterConfigs({ handleAlterCallBack }) {
    const { user } = useUser()
    const userEmail = user.email

    const [open, setOpen] = useState(false)
    const [brandFilArr, setBrandFilArr] = useState([])
    const [brandFilters, setBrandFilters] = useState({
        brandName: '',
        skuInitial: '',
        brandTitle: '',
        profileId: '',
        createdBy: userEmail,
    })

    const handleOpen = () => setOpen(!open)

    const handleAddBrandFilters = () => {

        if (brandFilters.brandName && brandFilters.brandTitle){
            setBrandFilArr([...brandFilArr, brandFilters])
            setBrandFilters({ brandName: '', skuInitial: '', brandTitle: '', profileId: '', createdBy: userEmail })
        }
        else {
            window.alert('Brand name and brand title should not be empty!')
        }
    }

    const handleSubmit = () => {
        console.log('brandFilArr', brandFilArr);

        if (brandFilArr.length > 0) {
            axios.post('/api/brand-filters/add', { filters: brandFilArr })
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`);
                        handleAlterCallBack()
                        setOpen(!open);
                        setBrandFilArr([]);
                    } else {
                        window.alert(`${response.data.message}`);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    const errorMessage = error.response?.data?.message || 'An error occurred during add.';
                    window.alert(errorMessage);
                });
        } else {
            window.alert('No data to add. Please add filters before submitting.');
        }
    };
    

    return (
        <>
            <Button onClick={handleOpen} variant="gradient" color="indigo" className="group flex items-center">
                <Cog8ToothIcon className="h-5 w-5 mr-1 group-hover:animate-spin "/>Configurations
            </Button>
            <Dialog open={open} handler={handleOpen} className="bg-blue-gray-900 min-h-fit max-h-[50rem] overflow-y-auto">
                <DialogHeader className="text-white"><Cog8ToothIcon className="h-7 w-7 mr-1"/>Configure Settings</DialogHeader>
                <DialogBody className="flex flex-col">

                    <p className="font-bold ml-5 mb-3  flex items-center text-white">Set file paths<FolderIcon className=" ml-2 h-5 w-5"/></p>
                    <div className=" ml-7 mb-3 flex flex-col text-blue-500">
                        <div className="flex-row flex items-center m-1">
                        <p className="font-semibold">App Eagle</p>
                            <input
                                id="confirmNewPassword"
                                className="ml-5 px-2 w-64 bg-gray-700 bg-opacity-50 border focus:outline-none border-gray-600 rounded-md text-white"
                                value=""
                                type="text"
                                // onChange={e => setUserCredentials ({...userCredentials, confirmNewPassword: e.target.value})}
                            />
                        </div>
                        <div className="flex-row flex items-center m-1">
                        <p className="font-semibold">All Listings</p>
                            <input
                                id="confirmNewPassword"
                                className="ml-5 px-2 w-64 bg-gray-700 bg-opacity-50 border focus:outline-none border-gray-600 rounded-md text-white"
                                value=""
                                type="text"
                                // onChange={e => setUserCredentials ({...userCredentials, confirmNewPassword: e.target.value})}
                            />
                        </div>
                    </div>

                    <p className="font-bold ml-5 mb-3 flex items-center text-white">Create new brand filter<FunnelIcon className="ml-2 h-5 w-5"/></p>

                    <div className="flex text-blue-500 justify-around mb-2 pr-8">
                        <p className="font-bold">Brand</p>
                        <p className="font-bold">SKU Initial</p>
                        <p className="font-bold">Title</p>
                        <p className="font-bold">Profile ID</p>
                    </div>

                    <div className="w-full h-fit flex flex-col items-center justify-evenly space-y-2">
                        {brandFilArr.map((item, index) => (
                            <div key={index} className="w-full flex flex-row items-center justify-evenly">
                                <p className="font-bold text-white">{index + 1}.</p>
                                <input
                                    className="px-2 w-40 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white focus:outline-none"
                                    value={item.brandName}
                                    type="text"
                                    readOnly
                                />
                                <input
                                    className="px-2 w-40 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white focus:outline-none"
                                    value={item.skuInitial}
                                    type="text"
                                    readOnly
                                />
                                <input
                                    className="px-2 w-40 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white focus:outline-none"
                                    value={item.brandTitle}
                                    type="text"
                                    readOnly
                                />
                                <input
                                    className="px-2 w-40 bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white focus:outline-none"
                                    value={item.profileId}
                                    type="text"
                                    readOnly
                                />
                                <button className="text-red-500 font-semibold" onClick={() => setBrandFilArr(brandFilArr.filter((_, i) => i !== index))}>
                                    <XCircleIcon className="h-8 w-8"/>
                                </button>
                            </div>
                        ))}
                    </div>
                    
                    <div className="w-full h-fit flex flex-row items-center justify-evenly mt-4 bg-blue-900 p-2 rounded-lg">
                        <p className="font-bold text-white">{brandFilArr.length + 1}.</p>
                        <input
                            id="brandName"
                            className="px-2 w-40 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none"
                            value={brandFilters.brandName}
                            type="text"
                            onChange={e => setBrandFilters({ ...brandFilters, brandName: e.target.value })}
                        />
                        <input
                            id="brandTitle"
                            className="px-2 w-40 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none"
                            value={brandFilters.skuInitial}
                            placeholder="optional"
                            type="text"
                            onChange={e => setBrandFilters({ ...brandFilters, skuInitial: e.target.value })}
                        />
                        <input
                            id="brandTitle"
                            className="px-2 w-40 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none"
                            value={brandFilters.brandTitle}
                            type="text"
                            onChange={e => setBrandFilters({ ...brandFilters, brandTitle: e.target.value })}
                        />
                        <input
                            id="profileId"
                            className="px-2 w-40 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none"
                            value={brandFilters.profileId}
                            placeholder="optional"
                            type="text"
                            onChange={e => setBrandFilters({ ...brandFilters, profileId: e.target.value })}
                        />
                        <PlusCircleIcon className="h-8 w-8 hover:scale-105 hover:rotate-180 transition text-blue-500 cursor-pointer" onClick={handleAddBrandFilters}/>
                    </div>

                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={handleOpen}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={handleSubmit}>Save</Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}
