import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { XCircleIcon } from '@heroicons/react/24/outline'
import React, { useState, useEffect, useRef  } from 'react'

const CustomSelect = ({ brandFilters, updateFilter }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const dropdownRef = useRef(null);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleOptionClick = (option) => {
        setSelectedOption(option)
        updateFilter(option)
        setSearchTerm(option)
        setIsDropdownOpen(false)
    }

    const filteredOptions = brandFilters.filter((item) =>
        item.brandName.toLowerCase().includes(searchTerm.toLowerCase())
    )

    filteredOptions.sort((a, b) => a.brandName.localeCompare(b.brandName));

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    const handleClearInput = () =>{
        setSearchTerm('')
        setSelectedOption('')
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative w-72 mx-2" ref={dropdownRef}>
            <div className="relative">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setIsDropdownOpen(true)}
                    className="block w-full p-1 text-white bg-gray-800 bg-opacity-40 border border-gray-600 rounded-md shadow-sm focus:outline-none"
                    placeholder="Select Brand"
                />
                {searchTerm ? 
                <XCircleIcon onClick={handleClearInput} className='h-7 w-7 absolute inset-y-1 right-[2.5rem] cursor-pointer text-white hover:text-red-300' />
                : ''}
                
                <button
                    className="absolute inset-y-0 right-0 px-2 text-white bg-gray-700 rounded-r-md"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                    {!isDropdownOpen ? <ChevronDownIcon className='text-white h-4 w-4'/> : <ChevronUpIcon className='text-white h-4 w-4'/>} 
                </button>
            </div>
            {isDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-gray-600 rounded-md shadow-lg h-[20rem] overflow-y-auto ">
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((item) => (
                            <div
                                key={item.brandName}
                                onClick={() => handleOptionClick(item.brandName)}
                                className="px-4 py-2 text-blue-500 cursor-pointer hover:bg-gray-700"
                            >
                                {item.brandName}
                            </div>
                        ))
                        ) : (
                            <div className="px-4 py-2 text-gray-400">No results found</div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CustomSelect
