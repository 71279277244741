import React, { useState } from 'react'

import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"

import Sidebar from '../../../../Components/Sidebar'
import FloatBar from '../../../../Components/FloatBar'

import neural2 from '../../../../assets/neural-networks.webp'
import { ArrowUturnLeftIcon, TicketIcon } from '@heroicons/react/24/solid'
import { Button } from '@material-tailwind/react'
import { DataTable } from './DataTable'
import { FilterConfigs } from './FilterConfigs'
import { GenerateFile } from './GenerateFile'
import { FilterViews } from './FilterViews'

const DataGathering = () => {

    const navigate = useNavigate()

    const [isAltered, setIsAltered] = useState(false)

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleAlterCallBack = () => {
        setIsAltered(prevstate => !prevstate)
    }

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-hidden'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.8)), url(${neural2})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

        <FloatBar/>
            <div className='flex flex-row gap-2 w-[95%] h-full  '>
                <Sidebar/>
                <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
                    <ToastContainer/>
                    <div className='w-full h-fit flex justify-between p-4 text-white'>
                        <p className=' text-xl flex items-center'><ArrowUturnLeftIcon className=' mr-5 h-6 w-6 cursor-pointer hover:scale-125 transition' onClick={handleGoBack}/>Data Gathering</p>
                    </div>
                    <div className='w-full h-fit flex flex-row items-center gap-8 text-white pl-4 my-3'>
                        <GenerateFile handleAlterCallBack={handleAlterCallBack} isAltereds={isAltered}/>
                        <FilterConfigs handleAlterCallBack={handleAlterCallBack}/>
                        <FilterViews isAltereds={isAltered}/>
                    </div>
                    <div className='w-full h-full p-2 pl-4 flex  justify-center m-1 overflow-y-auto overflow-x-auto'>
                        <DataTable isAltereds={isAltered}/>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default DataGathering