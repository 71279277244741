import axios from "axios"
import React, { useEffect, useState } from "react"

import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Tooltip, Typography, Card } from "@material-tailwind/react"
import { CheckIcon, Cog8ToothIcon, FunnelIcon, PencilSquareIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid"
import SearchInput from "../../../../Components/SearchInput"
import Pagination from "../../../../Components/Pagination"

const tbl_header = ["Brand", "SKU Initial", "Title", "Profile ID", "Actions"]

export function FilterViews({ isAltereds }) {
    const [open, setOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [isAltered, setIsAltered] = useState(false)
    const [brandFilters, setBrandFilters] = useState([])
    const [editFilter, setEditFilter] = useState({ skuInitial: '', brandTitle: '', profileId: ''})
    
    const filtersPerPage = 9
    const totalPages = Math.ceil(brandFilters.length / filtersPerPage)
    const indexOfLastFilter = currentPage * filtersPerPage
    const indexOfFirstFilter = indexOfLastFilter - filtersPerPage

    const filteredRequests = brandFilters.filter(item =>
        (item.brandName && item.brandName.toString().includes(searchTerm.trim())) ||
        (item.brandTitle && item.brandTitle.toString().includes(searchTerm.trim())) ||
        (item.skuInitial && item.skuInitial.toString().includes(searchTerm.trim())) ||
        (item.profileId && item.profileId.toString().includes(searchTerm.toLowerCase())) 
    )
    filteredRequests.sort((a, b) => a.brandName.localeCompare(b.brandName));
    const filteredData = filteredRequests.slice(indexOfFirstFilter, indexOfLastFilter)

    useEffect (() => {
        axios.get('/api/brand-filters/all-filters')
        .then(response => {
            if (response.data.message) {
                console.log("Cannot get batches")
            } else {
                const filters = response.data.result.flatMap(item => item.filters)
                setBrandFilters(filters)
                console.log(filters)
            } 
        })
        .catch(error => {
            console.error('Error:', error)
        })
    },[isAltered, isAltereds])

    const handleOpen = () => setOpen(!open)

    const handleSearch = (event) => {
        setSearchTerm(event.target.value)
        setCurrentPage(1)
    }

    const handleEditMode = (index) => setEditMode((prevState => (prevState === index ? false : index)))

    const handleSaveEdits = (item) => {
        const updatedFilter = {
            brandName: item.brandName,
            skuInitial: editFilter.skuInitial || item.skuInitial || '',
            brandTitle: editFilter.brandTitle || item.brandTitle || '',
            profileId: editFilter.profileId || item.profileId || ''
        };
    
        axios.put(`/api/brand-filters/update-filter/${item.brandName}`, updatedFilter)
            .then(response => {
                if (response.data.message) {
                    setEditMode(prevState => !prevState);
                    setIsAltered(prevState => !prevState);
                    setEditFilter({ skuInitial: '', brandTitle: '', profileId: '' });
                    window.alert(`${response.data.message}!`);
                } else {
                    window.alert('Unknown error occurred');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || 'An error occurred during update.';
                window.alert(errorMessage);
            });
    };

    const handleDeleteFilters = (brandName) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete this brand filter: ${brandName}?`)

        if (isConfirmed) {
            axios.delete(`/api/brand-filters/delete-filter/${brandName}`)
                .then(response => {
                    if (response.data.message) {
                        window.alert(`${response.data.message}!`)
                        setIsAltered(prevState => !prevState)
                    } else {
                        window.alert(`${response.data.message}`)
                        console.log('Error:', response.data.error)
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                })
        }
    }

    return (
        <>
        <Tooltip content="View Filters">
            <FunnelIcon onClick={handleOpen} className="bg-white bg-opacity-15 hover:bg-opacity-30 rounded-lg p-1 h-10 w-10 text-cyan-300 cursor-pointer hover:scale-110 transition mr-1 group-hover:animate-spin "/>
        </Tooltip>

        <Dialog open={open} handler={handleOpen} className="bg-blue-gray-900" size="lg">
            <DialogHeader className="text-white"><Cog8ToothIcon className="h-7 w-7 mr-1"/>Brand Filter Settings</DialogHeader>
            <DialogBody className="flex flex-col">
            <SearchInput  value={searchTerm} onChange={handleSearch}/>
                <Card className="min-h-fit max-h-[45rem] w-full  bg-[#101927] rounded-b-none overflow-y-auto">
                    <table className="w-full min-w-max table-auto text-center">
                    <thead>
                        <tr>
                        {tbl_header.map((head) => (
                            <th
                            key={head}
                            className="border-b border-[#374050] bg-[#1E2936] p-4 sticky top-0 z-10"
                            >
                            <Typography
                                variant="small"
                                color="white"
                                className="font-normal leading-none opacity-70"
                            >
                                {head}
                            </Typography>
                            </th>
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item, index) => {
                        const globalIndex = indexOfFirstFilter + index

                        return (
                            <tr className="even:bg-blue-gray-700 hover:bg-blue-900" key={item.brandName}>
                            <td className="p-5">
                                <Typography
                                variant="small"
                                color="white"
                                className="font-normal"
                                >
                                {item.brandName}
                                </Typography>
                            </td>
                            {editMode === globalIndex ?
                                <td className="p-4">
                                    <input
                                    className="px-2 w-40 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none"
                                    defaultValue={item.skuInitial}
                                    type="text"
                                    onChange={e => setEditFilter({ ...editFilter, skuInitial: e.target.value })}
                                    /> 
                                </td>
                            :
                                <td className="p-4">
                                <Typography
                                variant="small"
                                color="white"
                                className="font-normal"
                                >
                                {item.skuInitial ? item.skuInitial : '-----'}
                                </Typography>
                            </td>
                            }
                            {editMode === globalIndex ?
                                <td className="p-4">
                                    <input
                                    className="px-2 w-40 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none"
                                    defaultValue={item.brandTitle}
                                    type="text"
                                    onChange={e => setEditFilter({ ...editFilter, brandTitle: e.target.value })}
                                    />  
                                </td>
                            :
                                <td className="p-4">
                                    <Typography
                                    variant="small"
                                    color="white"
                                    className="font-normal"
                                    >
                                    {item.brandTitle} 
                                    </Typography>
                                </td>
                            }
                            {editMode === globalIndex ?
                                <td className="p-4">
                                    <input
                                    className="px-2 w-40 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none"
                                    defaultValue={item.profileId}
                                    type="text"
                                    onChange={e => setEditFilter({ ...editFilter, profileId: e.target.value })}
                                    />                                    
                                </td>
                            :
                                <td className="p-4">
                                    <Typography
                                    variant="small"
                                    color="white"
                                    className="font-normal"
                                    >
                                    {item.profileId ? item.profileId : '-----'}
                                    </Typography>
                                </td>
                            }

                            <td className="p-4">
                                {editMode === globalIndex ? 
                                <div className="flex flex-row gap-2 items-center justify-center">
                                <CheckIcon className="h-6 w-6 hover:scale-110 transition text-green-500 cursor-pointer" onClick={() => handleSaveEdits(item)}/>
                                <XMarkIcon className="h-6 w-6 hover:scale-110 transition text-red-500 cursor-pointer" onClick={() => handleEditMode(item.brandName)}/>
                                </div>
                                :
                                <div className="flex flex-row gap-2 items-center justify-center">
                                <PencilSquareIcon className="h-6 w-6 hover:scale-110 transition text-blue-500 cursor-pointer" onClick={() => handleEditMode(globalIndex)}/>
                                <TrashIcon className="h-6 w-6 hover:scale-110 transition text-red-500 cursor-pointer" onClick={() => handleDeleteFilters(item.brandName)}/>
                            </div>
                                }

                            </td>
                            </tr>
                        )
                        })}
                    </tbody>
                    </table>
                </Card>
                <div className="flex flex-row items-center rounded-b-md justify-center h-fit w-full p-2 bg-blue-100">
                    <Pagination
                        currentPage={currentPage}
                        customClass={`bg-opacity-50`}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                    />
                </div>

            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="green">Save</Button>
            </DialogFooter>
        </Dialog>
    </>
    )
}
