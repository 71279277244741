import React, { useState } from "react"

import { RefundStepper } from "./RefundStepper"
import { CurrencyDollarIcon } from "@heroicons/react/24/solid"
import { Button ,Dialog, DialogHeader, DialogFooter, Tooltip } from "@material-tailwind/react"

export function RefMethodModal({ data, refundMethod }) {
    const { orderId, rmaId, sku, refTotalAmt } = data
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(!open)

return (
    <>
        <Tooltip content="Refund">
            <CurrencyDollarIcon onClick={handleOpen} className='h-8 w-full text-orange-500 hover:scale-150 transition cursor-pointer'/>
        </Tooltip>
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader className="bg-[#19191B] rounded-t-lg border-x border-t border-orange-500">
                <p className="text-white">Refund Process</p>
            </DialogHeader>
            <div className="flex flex-row min-h-[20rem] max-h-fit border-gray-500 text-white gap-4 p-5 bg-[#0b0b1d] ">
                <div className="w-[50%] h-full flex flex-col justify-center text-gray-400">
                    <p className="font-bold text-md my-2">Refund method <span className="font-normal text-white bg-blue-400 rounded-md p-1">{
                        refundMethod === 'noNeedRef' ? 'No Need Refund' :
                        refundMethod === 'standRef' ? 'Standard Refund' :
                        refundMethod === 'regRef' ? 'Regular Refund' : 
                        refundMethod === 'dollarRef' ? '$1 Refund' : 
                        refundMethod === 'outboundRef' ? 'Outbound Shipping' :
                        refundMethod === 'lessRef' ? 'Less Shipping ' : 
                        refundMethod === 'resFeeRef' ? 'Restocking Fee Refund' : 
                        'No refund method found!'
                        }</span></p>
                    <p className="font-bold text-md my-2">Order ID <span className="font-normal text-white bg-blue-400 rounded-md p-1">{orderId}</span></p>
                    <p className="font-bold text-md my-2">RMA ID <span className="font-normal text-white bg-blue-400 rounded-md p-1">{rmaId}</span></p>
                    <p className="font-bold text-md my-2">SKU <span className="font-normal text-white bg-blue-400 rounded-md p-1">{sku}</span></p>
                    <p className="font-bold text-md my-2">Refund Total <span className="font-normal text-white bg-blue-400 rounded-md p-1">{refTotalAmt}</span></p>
                </div>
                <div className="w-[50%] h-[95%] ">
                    <RefundStepper/>
                </div>
            </div>
            <DialogFooter className="space-x-2 bg-[#19191B] border-x border-b border-orange-500 rounded-b-lg">
                <Button variant="gradient" color="red" onClick={handleOpen}>
                    close
                </Button>
                <Button variant="gradient" className="flex items-center" color="orange" onClick={handleOpen}>
                    <CurrencyDollarIcon className="h-5 w-5 mr-1"/>Refund
                </Button>
            </DialogFooter>
        </Dialog>
    </>
    )
}