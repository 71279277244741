import React, { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"
import { useUser } from '../../../store/userState'

import Sidebar from '../../../Components/Sidebar'
import FloatBar from '../../../Components/FloatBar'
import CouponsTable from './CouponsTable'
import neural2 from '../../../assets/neural-networks.webp'
import { ArrowUturnLeftIcon, TicketIcon } from '@heroicons/react/24/solid'
import { Button } from '@material-tailwind/react'

const CouponPromotions = () => {

    const navigate = useNavigate()
    const { user } = useUser()
    const userEmail = user.email

    const [isAltered, setIsAltered] = useState(false)
    const [coupon, setCoupon] = useState('')

    const handleGoBack = () => {
        navigate(-1)
    }
    const handleSetCoupon = (event) => {
        setCoupon(event.target.value)
    };

    const generateTimestamp = () => {
        const now = new Date()
        
        const format = num => String(num).padStart(2, '0')
        return `${now.getFullYear()}${format(now.getMonth() + 1)}${format(now.getDate())}${format(now.getHours())}${format(now.getMinutes())}${format(now.getSeconds())}`
    }

    const handleRequestCoupons = () => {
        if (!coupon) {
            toast.error("Coupon ID is required.");
            return;
        }

        const batchImportData = {
            batchId: generateTimestamp(),
            couponId: coupon ,
            addedBy: userEmail,
        }
    
        axios.post('/api/coupon-promotions/add', batchImportData)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`)
                setIsAltered(prevstate => !prevstate)
                setCoupon('')
            } else {
                toast.error(`${response.data.message}`)
            }
        })
        .catch(error => {
            console.error('Error:', error)
            const errorMessage = error.response?.data?.message || 'An error occurred during add.'
            toast.error(errorMessage)
        })
    }

    return (
        <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-hidden'
        style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.8)), url(${neural2})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

        <FloatBar/>
            <div className='flex flex-row gap-2 w-[95%] h-full  '>
                <Sidebar/>
                <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
                    <ToastContainer/>
                    <div className='w-full h-fit flex justify-between p-4 text-white'>
                        <p className=' text-xl flex items-center'><ArrowUturnLeftIcon className=' mr-5 h-6 w-6 cursor-pointer hover:scale-125 transition' onClick={handleGoBack}/>Coupon Promotions</p>
                    </div>

                    <div className='w-fit h-fit flex items-center p-2 flex-row gap-8 bg-gray-700 bg-opacity-50 text-white pl-4 my-3 rounded-md'>
                        <p>Enter New Coupon:</p>
                        <div className="flex items-center justify-center bg-gray-800 overflow-hidden p-1 border border-white border-opacity-30 rounded-lg shadow-md h-9">
                            <input
                                className="w-72 h-full border-none outline-none text-sm bg-gray-800 text-white font-semibold caret-orange-500 pl-2"
                                type="text"
                                value={coupon}
                                placeholder="f9222564-b3e2-4fc6-8e3b-114a96cd0c95"
                                onChange={handleSetCoupon}
                            />
                            <div className="flex items-center justify-center relative w-10 h-6 bg-gray-800 border border-white border-opacity-20 rounded-md">
                                <TicketIcon className="text-teal-300 fill current h-full w-full"/>
                            </div>
                            </div>

                        <Button variant="gradient" className='hover:bg-blue-500' onClick={handleRequestCoupons}>Submit</Button>
                    </div>
                    
                    <div className='w-full h-full p-2 pl-4 flex  justify-center m-1 overflow-y-auto overflow-x-auto'>
                        <CouponsTable isAltered={isAltered}/>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default CouponPromotions