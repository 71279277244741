import React, { useState } from "react";
import axios from "axios";
import { useUser } from "../../../store/userState";
import { toast } from "react-toastify";

import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";

export function AddCases({ onAlterState }) {
    const { user } = useUser();
    const userEmail = user.email

    const [open, setOpen] = useState(false);
    const [caseIds, setCaseIds] = useState([]);
    const [newCaseId, setNewCaseId] = useState("");

    const handleOpen = () => setOpen(!open)
    
    const handleCaseIdChange = (event) => {
        setNewCaseId(event.target.value);
    };

    const handleAddCases = () => {
        // Trim the input and validate it only contains 11 numeric characters
        const trimmedCaseId = newCaseId.trim();
        const isValidCaseId = /^\d{11}$/.test(trimmedCaseId);
        
        if (isValidCaseId && !caseIds.includes(trimmedCaseId)) {
            setCaseIds([...caseIds, trimmedCaseId]);
            setNewCaseId('');
        } else {
            if (!isValidCaseId) {
                alert("Please enter a valid case id");
            } else {
                alert("Case ID already exists.");
            }
        }
    };
    

    const handleSubmitCases = () => {

        const amzCasesAdd = {
            caseId: caseIds,
            addedBy: userEmail
        }

        axios.post('/api/amz-cases/add', amzCasesAdd)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`);
                setOpen(!open)
                setCaseIds([])
                setNewCaseId('')
                onAlterState();
            } else {
                toast.error(`${response.data.message}`)
            }
        })
        .catch(error => {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred during add.';
            toast.error(errorMessage);
        });
    }

    return (
        <>
            <Button onClick={handleOpen} variant="gradient" color="blue">
                Add Cases
            </Button>
            <Dialog
                open={open}
                handler={handleOpen}
                animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}
            >
                <DialogHeader>Add new cases</DialogHeader>
                <DialogBody divider>
                    <div className="flex flex-col justify-center items-center w-[100%] gap-5">
                        <div className="flex flex-row gap-2 w-fit p-2 items-center">
                            <input
                                type="text"
                                placeholder="Case ID"
                                aria-label="Case ID"
                                className="block w-[15rem] rounded-2xl border border-neutral-300 bg-transparent p-5 text-base/6 text-neutral-950 ring-4 ring-transparent transition placeholder:text-neutral-500 focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5"
                                value={newCaseId}
                                maxLength={11}
                                onChange={handleCaseIdChange}
                            />
                            <button className="bg-blue-500 p-3 text-white rounded-md h-fit" onClick={handleAddCases}>
                                <PaperAirplaneIcon className="h-6 w-6" />
                            </button>
                        </div>
                        {/* Render added cases */}
                        <div className="flex flex-wrap gap-4 text-white">
                            {caseIds.map((caseId, index) => (
                                <div key={index} className="bg-blue-500 p-1 px-2 rounded-lg flex flex-row gap-1 items-center">
                                    {caseId} <button className="text-red-500 font-semibold" onClick={() => setCaseIds(caseIds.filter((_, i) => i !== index))}>X</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="blue" onClick={handleSubmitCases}>
                        <span>Submit</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}
