import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import { Accordion, AccordionHeader, AccordionBody, Input } from "@material-tailwind/react";
import { CheckCircleIcon, PencilSquareIcon, TrashIcon, WrenchIcon, XCircleIcon } from "@heroicons/react/24/solid";
import ToolsManagement from "./ToolsManagement";

const CUSTOM_ANIMATION = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
};

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

export default function DeptToolsAccordion() {
    const [open, setOpen] = useState(-1);
    const [isAltered, setIsAltered] = useState(false)
    const [departments, setDepartments] = useState([]);
    const [departmentTools, setDepartmentTools] = useState([]);
    const [editDepartment, setEditDepartment] = useState({department: ''})
    const [isEditable, setIsEditable] = useState(false);

    const toggleEdit = (index) => {
        setIsEditable(prevState => (prevState === index ? false : index)); 
    };

    const handleOpen = (index) => {
        if (open === index) {
            setOpen(-1);
            setDepartmentTools([]);
        } else {
            setOpen(index);
            setDepartmentTools(departments[index].tools); // Update department tools
        }
    };

    useEffect(() => {
        axios.get('/api/departments/get')
            .then(response => {
                if (response.data.message) {
                    console.log('Cannot get departments')
                } else {              
                    setDepartments(response.data.result)
                }
            })
    }, [isAltered]);

    const callback = () => setIsAltered(!isAltered)

    const handleDeleteDept = (index) => {
        const deptToDelete = departments[index];
        const isConfirmed = window.confirm(`Are you sure you want to delete '${deptToDelete.department}' department?`);
        
        if (isConfirmed) {
            axios.delete(`/api/departments/delete/${deptToDelete._id}`, editDepartment)
                .then(response => {
                    if (response.data.message) {
                        toast.success(`${response.data.message}`);
                        console.log(`Deleted department ${deptToDelete.department}`)
                        setDepartments(prevUsers => prevUsers.filter((idx) => idx !== index));
                        setIsAltered(!isAltered)
                    } else {
                        toast.error(`${response.data.message}`);
                        console.log('Error:', response.data.error);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    const handleEditDept = (index) => {
        const deptToEdit = departments[index];

        console.log("Department index: ", deptToEdit)
    
        axios.put(`/api/departments/update/${deptToEdit._id}`, editDepartment)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`);
                setEditDepartment({ department: '' });
                toggleEdit(false);
                setIsAltered(!isAltered)
            } else {    
                toast.error(`${response.data.message}`);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred during add.';
            toast.error(errorMessage);
        });
    }

    return (
        <>
            {departments.map(({ department }, index) => (
                <div key={index} className="w-full flex flex-row gap-1 items-center justify-center bg-gray-500 bg-opacity-10 p-2 m-1 rounded-md">
                    <div className="w-full bg-blue-800 bg-opacity-50 m-1 p-2 rounded-md">
                        <Accordion open={open === index} animate={CUSTOM_ANIMATION} icon={<Icon id={index} open={open}/>}>

                        <AccordionHeader className="text-white hover:text-none" onClick={() => handleOpen(index)}>
                            {isEditable === index ? 
                            <Input variant="standard" placeholder="" label="Edit mode" 
                            className="w-[20%] text-white font-semibold text-xl" 
                            defaultValue={department}
                            success
                            onChange={e => setEditDepartment({ ...editDepartment, department: e.target.value })}/> 
                            : department}
                        </AccordionHeader>

                            {open === index && departmentTools.map(({ toolName }, toolIndex) => (
                                <AccordionBody key={toolIndex} className="text-white flex flex-row gap-2">
                                    <WrenchIcon className="h-4 w-4"/>{toolName}
                                </AccordionBody>
                            ))}
                        </Accordion>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                        {isEditable === index ? 
                        <>
                        <button className="p-1 text-green-500 w-fit h-fit hover:scale-125 hover:text-green-700 transition"
                        onClick={() => handleEditDept(index)}><CheckCircleIcon className="h-6 w-6"/></button>
                        <button className="p-1 text-red-500 w-fit h-fit hover:scale-125 hover:text-red-700 transition"
                        onClick={() => toggleEdit(index)}><XCircleIcon className="h-6 w-6"/></button>
                        </> :
                        <>
                            {open === index && (
                                <ToolsManagement departmentTools={departmentTools} 
                                toolIds={departments[index].tools.map(tool => tool._id)}
                                departmentId={departments[index]._id}
                                callback={callback}/>
                            )}
                            <button className="p-1 text-green-500 w-fit h-fit hover:scale-125 hover:text-green-700 transition"
                            onClick={() => toggleEdit(index)}><PencilSquareIcon className="h-6 w-6"/></button>
                            <button className="p-1 text-red-500 w-fit h-fit hover:scale-125 hover:text-red-700 transition"
                            onClick={() => handleDeleteDept(index)}><TrashIcon className="h-6 w-6"/></button>
                        </>}

                    </div>
                </div>
            ))}
            <ToastContainer/>
        </>
    );
}
